import React from "react";
import Wrapper from "./sidebarLeftMobile.styles";
import { useSelector } from "react-redux";
import SidebarContent from "./sidebarContent";
import { APP_STATE } from "../../constants/commonConstants";
import Footer from "../footer/footer";

const SidebarLeftMobile = ({ showSidebarLeft, shouldShowSidebarContent, setShowSidebarLeft, setShowSidebarRight }) => {
  const { appState } = useSelector((state) => state.Client);
  const isSidebarReady = appState === APP_STATE.READY;

  return (
    <Wrapper className={isSidebarReady && showSidebarLeft ? "show" : ""}>
      <div className="sidebar">
        <SidebarContent
          shouldShowSidebarContent={shouldShowSidebarContent}
          setShowSidebarLeft={setShowSidebarLeft}
          setShowSidebarRight={setShowSidebarRight}
          menuSide= "left"
        />
        <div className="footer-group">
          <Footer />
        </div>
      </div>
    </Wrapper>
  );
};

export default SidebarLeftMobile;
