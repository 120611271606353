import actions from "./actions";
import { APP_STATE } from "../../constants/commonConstants";
import blankClientData from '../../models/blankClient.model';

const accountWillReceiveTransactions = (accountNumber) => (account) => account.accountNumber === accountNumber;

const initState = {
  clientData: {
    eligibilityResult: {}
  },
  accountId: "",
  payFullArrears: false,
  arrearsAmount: "",
  appState: APP_STATE.LOADING,
  eligibilityState: APP_STATE.LOADING,
  isFetchingTransactions: false,
};

const selectOpenAccounts = (state) => state.clientData.openAccounts;
const selectClosedAccounts = (state) => state.clientData.closedAccounts;

function createNewAccountWithTransactions(accountToAddTransactionsTo, payload, state, type) {
  const accountWithTransactions = {
    ...accountToAddTransactionsTo,
    transactions: {
      items: payload.transactions
    },
  };

  let accountState;

  if (type === "open") {
    accountState = selectOpenAccounts;
  }
  if (type === "closed") {
    accountState = selectClosedAccounts;
  }

  return accountState(state).map((account) => accountWillReceiveTransactions(payload.accountNumber)(account) ? accountWithTransactions : account);
}

export default function (state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case actions.GET_CLIENT_DATA_START:
      return {
        ...state,
        appState: APP_STATE.LOADING,
        eligibilityState: APP_STATE.LOADING
      };
    case actions.GET_CLIENT_DATA_FAILED:
      return {
        ...state,
        appState: APP_STATE.ERROR,
        eligibilityState: APP_STATE.ERROR,
        clientData: blankClientData
      };
    case actions.GET_CLIENT_DATA_SUCCESS:
      return {
        ...state,
        appState: APP_STATE.READY,
        eligibilityState: APP_STATE.READY,
        clientData: payload,
      };
    case actions.GET_ACCOUNT_TRANSACTIONS_START:
      return {
        ...state,
        isFetchingTransactions: true,
      }
    case actions.GET_ACCOUNT_TRANSACTIONS_SUCCESS:
      const accountToAddTransactionsTo = selectOpenAccounts(state).find(accountWillReceiveTransactions(payload.accountNumber));
      const closedAccountToAddTransactionsTo = selectClosedAccounts(state).find(accountWillReceiveTransactions(payload.accountNumber));
      if (typeof (accountToAddTransactionsTo) === "undefined") {
        if (closedAccountToAddTransactionsTo) {
          return {
            ...state,
            clientData: {
              ...state.clientData,
              closedAccounts: createNewAccountWithTransactions(closedAccountToAddTransactionsTo, payload, state, "closed")
            },
            isFetchingTransactions: false,
          };
        }
        
        return state;
      }

      return {
        ...state,
        clientData: {
          ...state.clientData,
          openAccounts: createNewAccountWithTransactions(accountToAddTransactionsTo, payload, state, "open")
        },
        isFetchingTransactions: false,
      };
    case actions.GET_ACCOUNT_TRANSACTIONS_FAILED:
      return {
        ...state,
        isFetchingTransactions: false,
      }
    case actions.SET_ACCOUNT_ID:
      return {
        ...state,
        accountId: payload,
      };
    case actions.SET_FULL_ARREARS:
    return {
      ...state,
      payFullArrears: true,
    };
    case actions.SET_ARREARS_AMOUNT:
      return {
        ...state,
        arrearsAmount: payload,
      };
    case actions.SET_INELIGIBLE:
      return {
        ...state,
        eligibilityResult: {
          ...state.eligibilityResult,
          [payload]: {
            isEligible: false
          }
        }
      };
    case actions.CLEAR_ACCOUNT_INFO:
      return {
        ...state,
        accountId: "",
        payFullArrears: false,
      };
    default:
      return state;
  }
}


