import VARIABLES from "./variables";

const projectSpecific = {
  portalWell: {
    default: {
      border: VARIABLES.COLORS.darkGreen,
      background: VARIABLES.COLORS.darkGreen
    },
    pill: {
      border: VARIABLES.COLORS.lighterGray,
      background: VARIABLES.COLORS.lighterGray
    },
    formNote: {
      border: VARIABLES.COLORS.darkGreen,
      background: VARIABLES.COLORS.lightGreen
    },
    white: {
      border: VARIABLES.COLORS.darkGreen,
      background: VARIABLES.COLORS.white
    }
  },
  accordion: {
    headColor: VARIABLES.COLORS.white, // 1
    headBackgroundOne: VARIABLES.COLORS.slate, // 0
    headBackgroundTwo: VARIABLES.COLORS.darkerGray, // 4
    panelBackground: VARIABLES.COLORS.slate, // 2
  },
  ballBreadCrumb: {
    main: VARIABLES.COLORS.lightGreen, // 3
    active: VARIABLES.COLORS.darkGreen, // 0, 2
    completeMobile: VARIABLES.COLORS.lightGreen, // 1
    color: VARIABLES.COLORS.gray, // 4
  },
};

export { projectSpecific };
