import React from "react";
import LoaderComponent from "./loader.style";
import { Spinner } from "./components";

export default function Loader({ ...props }) {
  const { type, message, loading } = props;

  return (
    loading !== false && (
      <LoaderComponent>
        {message && <span className="message">{message}</span>}
        <Spinner type={type} />
      </LoaderComponent>
    )
  );
}
