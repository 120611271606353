import { fgfConfig } from "@config/fgf";
import defaultTheme from "./default"
import nimbleTheme from "./nimble";
import { nimbleConfig } from "@config/nimble";

const defaultBrand = {
  themeName: "fgfTheme",
  file: defaultTheme,
  configFile: fgfConfig
} as const;

const nimbleBrand = {
  themeName: "nimbleTheme",
  file: nimbleTheme,
  configFile: nimbleConfig
} as const;

export const loadingBrand = {
  themeName:"loading",
  file: {},
  configFile: fgfConfig
} as const;

export type DefaultBrand = typeof defaultBrand;
export type NimbleBrand = typeof nimbleBrand;
export type LoadingBrand = typeof loadingBrand;

export const brands = {
  defaultBrand,
  nimbleBrand,
  loadingBrand
} as const

export type Brands = typeof brands;

export type Brand = {
  themeName: BrandNames;
  file: {};
  configFile: {};
};

export type BrandData = {
  brand: Brand;
  brandKey: BrandKeys;
};
  
export type BrandKeys = keyof typeof brands;
export const brandKeys: BrandKeys[] = Object.keys(brands) as BrandKeys[];

export type BrandNames = 
  | DefaultBrand['themeName']
  | NimbleBrand['themeName']
  | LoadingBrand['themeName'];

export const determineBrandAndBrandKey = (brandKey: BrandKeys): BrandData => {
  if (brandKey === "loadingBrand") {
    return { brandKey, brand: brands["loadingBrand"] };
  }
  if (Object.keys(brands).includes(brandKey)) {
    return { brandKey, brand: brands[brandKey] };
  }
  return { brandKey: "defaultBrand", brand: brands["defaultBrand"] };
};

const hasNonEmptyHost = (host?: string): host is string => {
  return (!!host && host.length > 0);
}
const isNimbleTheme = (host: string): host is BrandKeys => {
  return host.includes('nimble'); 
}
const isFgfTheme = (host: string): host is BrandKeys => {
  return host.includes('fairgofinance') || host.includes('fgf');
}

const determineBrandFromHost = (host: string): BrandKeys => {
  if (isNimbleTheme(host)) {
    return 'nimbleBrand';
  }
  if (isFgfTheme(host)) {
    return 'defaultBrand';
  }
  return 'defaultBrand';
}

export const getBrandFromHost = (host?: string): BrandKeys => {
  const hasHost = hasNonEmptyHost(host);
  if (hasHost) {
    return determineBrandFromHost(host);
  }
  return 'defaultBrand';
};
