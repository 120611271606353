import { useEffect } from "react";

const messageEventName = "message";

const useOnFairGoMessage = (messageId, callBack) => {
  useEffect(() => {
    const messageIdEventListener = messageEvent => {
      if (
        messageEvent &&
        messageEvent.data &&
        messageEvent.data.messageId === messageId
      ) {
        callBack(messageEvent.data.value);
      }
    };
    
    window.addEventListener(messageEventName, messageIdEventListener);
    return () => window.removeEventListener(messageEventName, messageIdEventListener);
    }, [messageId, callBack])
};

export default useOnFairGoMessage;