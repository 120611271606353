import styled, { css } from "styled-components";

export default styled.div(({ theme }) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .mid-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mid-container img {
    height: 40px;
    width: 90px;
  }

  .right-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 15px;
  }

  .left-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
  }

  .right-container button,
  a {
    background: transparent;
    border: 0px;
    cursor: pointer;
  }

  .right-container button svg,
  a svg {
    z-index: 2;
  }

  .left-container button,
  a {
    background: transparent;
    border: 0px;
    cursor: pointer;
  }

  .left-container button svg,
  a svg {
    z-index: 2;
  }

  @media only screen and (min-width: ${theme.breakpoints.XM}) {
    display: none;
  }
`);
