import {
  faSignOutAlt,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCreditCard,
  faCalendarAlt,
  faBookmark,
  faEdit,
  faFolderOpen,
  faEnvelopeOpen,
} from "@fortawesome/free-regular-svg-icons";
import { PRIVATE_ROUTE, PUBLIC_ROUTE } from "../../route.constants";

export const menuListLeft = (isManageRepaymentsEnabled) =>  {
    return [
        { label: "Dashboard", link: PRIVATE_ROUTE.LANDING, icon: faBookmark, side: "left" },
        {
          label: "Loan History",
          link: PRIVATE_ROUTE.LOAN_HISTORY,
          icon: faCalendarAlt,
          side: "left"
        },
        { label: "Apply", link: PRIVATE_ROUTE.APPLY_NOW_MENU, icon: faFolderOpen, side: "left" },
        {
          label: "Manage Repayments",
          link: PRIVATE_ROUTE.MANAGE_REPAYMENTS,
          disabled: !isManageRepaymentsEnabled,
          icon: faEdit,
          side: "left"
        },
        { label: "Pay Online", link: PRIVATE_ROUTE.PAYMENT, icon: faCreditCard, side: "left" },
      ]
};

export const menuListRight = [
  { label: "Notifications", link: PRIVATE_ROUTE.LANDING, icon: faEnvelopeOpen, side: "right" },
  { label: "Help", link: PRIVATE_ROUTE.HELP, icon: faQuestionCircle, side: "right" },
  { label: "Logout", link: PUBLIC_ROUTE.LOG_OUT, icon: faSignOutAlt, side: "right" },
];
