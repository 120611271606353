import { all, takeLatest, call, put } from "redux-saga/effects";
import actions from "./actions";
import { getHelpContentData, getBannerContentData, getAccountNotificationsContentData } from "../../services/contentApi";

function* getHelpContentDataCall() {
  try {
    const response = yield call(getHelpContentData);
    const { data: { data } } = response;

    if (data) {
      yield put(actions.getHelpContentDataSuccess(data));
    } else {
      yield put(actions.getHelpContentDataFailed());
    }
  } catch (e) {
    yield put(actions.getHelpContentDataFailed());
  }
}

function* getBannerContentDataCall() {
  try {
    const response = yield call(getBannerContentData);
    const { data: { data } } = response;

    if (data) {
      yield put(actions.getBannerContentDataSuccess(data));
    } else {
      yield put(actions.getBannerContentDataFailed());
    }
  } catch (e) {
    yield put(actions.getBannerContentDataFailed());
  }
}

function* getAccountNotificationsDataCall() {
  try {
    const response = yield call(getAccountNotificationsContentData);
    const { data: { data } } = response;

    if (data) {
      yield put(actions.getAccountNotificationsDataSuccess(data));
    } else {
      yield put(actions.getAccountNotificationsDataFailed());
    }
  } catch (e) {
    yield put(actions.getAccountNotificationsDataFailed());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_HELP_CONTENT_DATA_START, getHelpContentDataCall),
    takeLatest(actions.GET_BANNER_CONTENT_DATA_START, getBannerContentDataCall),
    takeLatest(actions.GET_ACCOUNT_NOTIFICATIONS_DATA_START, getAccountNotificationsDataCall),
  ]);
}
