import React from "react";
import SidebarLeftMobile from "./sidebarLeftMobile";
import SidebarRightMobile from "./sidebarRightMobile";

const SidebarMobile = ({
  showSidebarLeft,
  setShowSidebarLeft,
  showSidebarRight,
  setShowSidebarRight,
  shouldShowSidebarContent,
  setShowNotifications
}) => (
  <>
    <SidebarLeftMobile
      showSidebarLeft={showSidebarLeft}
      showSidebarRight={showSidebarRight}
      setShowSidebarLeft={setShowSidebarLeft}
      setShowSidebarRight={setShowSidebarRight}
      shouldShowSidebarContent={shouldShowSidebarContent}
    />
    <SidebarRightMobile
      showSidebarLeft={showSidebarLeft}
      showSidebarRight={showSidebarRight}
      setShowSidebarLeft={setShowSidebarLeft}
      setShowSidebarRight={setShowSidebarRight}
      shouldShowSidebarContent={shouldShowSidebarContent}
      setShowNotifications={setShowNotifications}
    />
  </>
);

export default SidebarMobile;
