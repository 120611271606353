import React from "react";
import { useSelector } from "react-redux";
import Wrapper from "./mobileHeader.styles";
import FairGoLogo from "../../theme/assets/fairgo-logo.svg";
import NimbleLogo from "../../theme/assets/nimble-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { UnStyledLink } from "../textButton";
import { PRIVATE_ROUTE } from "../../route.constants";
import { useTheme } from 'styled-components';
import { brands } from "@theme/config";

const MobileHeader = ({ showSidebarLeft, setShowSidebarLeft, showSidebarRight, setShowSidebarRight }) => {
  const config = useSelector((state) => state.StartUp.brand.configFile);
  const themeName = useSelector((state) => state.StartUp.brand.themeName);
  const theme = useTheme();

  const { companyName } = config;

  const handleSetNavSideLeft = () => {
    if (showSidebarRight === true) {
      setShowSidebarRight(false);
    }
    setShowSidebarLeft(!showSidebarLeft);
  }

  const handleSetNavSideRight = () => {
    if (showSidebarLeft === true) {
      setShowSidebarLeft(false);
    }
    setShowSidebarRight(!showSidebarRight);
  }

  const handleCloseAllNav = () => {
    if (showSidebarLeft === true) {
      setShowSidebarLeft(false);
    }
    if (showSidebarRight === true) {
      setShowSidebarRight(false);
    }
  }

  let logo = FairGoLogo;
  if (themeName === brands.nimbleBrand.themeName) {
    logo = NimbleLogo;
  }

  return (
    <Wrapper onClick={handleCloseAllNav}>
      <div className="left-container">
        <button onClick={handleSetNavSideLeft}>
          <FontAwesomeIcon icon={faBars} color={theme.palette.header.active} size="lg" />
        </button>
      </div>
      <UnStyledLink to={PRIVATE_ROUTE.LANDING} className="mid-container">
        <img src={logo} alt={companyName}/>
      </UnStyledLink>
      <div className="right-container">
        <button onClick={handleSetNavSideRight}>
          <FontAwesomeIcon icon={faEllipsisV} color={theme.palette.header.active} size="lg" />
        </button>
      </div>
    </Wrapper>
  );
};

export default MobileHeader;
