import { useFormikContext } from "formik";

const useNumberInputFilter = (name, decimalPlaces = 0, customFieldUpdater = null) => {
  const { setFieldValue } = useFormikContext();

  const handleChange = (event) => {
    const strippedValue = event.target.value.replace(/[^\d.]/g, "");
    const fixedRegex = decimalPlaces === 0 ? /\d+/ : new RegExp(`^\\d+\\.?\\d{0,${decimalPlaces}}`, "g")
    const fixedValue = (strippedValue.match(fixedRegex) || [""])[0];
    (customFieldUpdater || setFieldValue)(name, fixedValue)
  }

  return handleChange;
};

export default useNumberInputFilter;