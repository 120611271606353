import { createJohnClientReplacer } from "./replacers";

export const createOptionalDispatchIntercept = (replacements) => (optionName, action) => {
  const isEnabled = window.sessionStorage.getItem(optionName) === "true";

  if (isEnabled) {
    replacements[action.type] = action;
  }
};

export const bootstrapReplacers = () => {
  const replacements = {};
  const optionalDispatchIntercept = createOptionalDispatchIntercept(replacements);

  // createEligibilityTrueReplacer(optionalDispatchIntercept);
  createJohnClientReplacer(optionalDispatchIntercept);

  return replacements;
};