import React, { useEffect } from "react";
import { useContext } from "../../utils/reactAdapter";
import { AuthContext } from "../../providers/authProvider";
import authenticatedPopsApi from "../../utils/portalApi";
import { getTokens } from "./getTokens";
import { environmentSettings } from "@constants/environmentSettings";

// potentially gut this and turn it into a custom hook
// that way if we want to use this inline of a component
// we can have that option. For now this solution works
// as we want to define the Auth context provider + setup axios
// integration inside the JSX of a single component
export const AxiosAuthInterceptor = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const interceptor = authenticatedPopsApi.interceptors.request.use(async (config) => {
      const { access_token: accessToken } = await getTokens(authContext);

      if (accessToken) {
        config.headers['Authorization'] = 'Bearer ' + accessToken;
      }

      if (environmentSettings.authority !== null && environmentSettings.authority.length > 0) {
        config.params = config.params || {};
        config.params["authority"] = environmentSettings.authority;
      }
      
      return config;
    });

    return () => { authenticatedPopsApi.interceptors.request.eject(interceptor) }
  }, [authContext]);

  return <></>;
};