/**
 * @description axios for fetching data via services
 */
import axios from "axios";
import { BASE_URL } from "../services/apiRoutes";
import { environmentSettings } from "../constants/environmentSettings";

const authenticatedPopsApi =  
  axios.create({
    baseURL: BASE_URL,
    timeout: 300000, // 5 min
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
      "Ocp-Apim-Subscription-Key": environmentSettings.ocpApimSubscriptionKey
    }
  });

export default authenticatedPopsApi;