import { eligibleKey, johnAccountKey } from "./constants";

export const bootstrapWindowsTools = () => {
  // i.e. window.setDevToolsEnabled({eligible: true, johnAccount: true})
  window.setDevToolsEnabled = ({ eligible = false, johnAccount = false, ...badOptions }) => {
    window.sessionStorage.setItem(eligibleKey, eligible.toString());
    window.sessionStorage.setItem(johnAccountKey, johnAccount.toString());

    if (Object.keys(badOptions).length > 0) {
      console.log(`The following options are not supported: ${Object.keys(badOptions).join(", ")}`);
    }
  }
};