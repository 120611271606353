const fonts = {
  heading: "'DM Sans', sans-serif",
  headingCase: "none",
  headingWeight: "700",
  body: "'DM Sans', sans-serif",
  sizes: {
    h1: "84px",
    h2: "48px",
    h3: "33px",
    h4: "24px",
    h5: "20px",
    h6: "18px",
    p0: "16px",
    p1: "15px",
    p2: "13px",
    p3: "12px",
    p4: "11px",
    p5: "10px"
  }
};

export { fonts };
