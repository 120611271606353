const breakpoints = {
  XS: "320px",
  S: "468px",
  M: "767px",
  MD: "959px",
  XM: "978px",
  XXM: "1327px",
  L: "1023px",
  LG: "1439px",
  XL: "1810px",
  fullScreen: "100%",
  windowWidth: "100vw",
  windowHeight: "100vh"
};

export { breakpoints };
