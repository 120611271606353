import { loadingBrand } from "@theme/config";
import actions from "./actions";

const initState = {
  brandKey: "loadingBrand",
  brand: loadingBrand
};

export default function(state = initState, action) {
  switch (action.type) {
    case actions.INITIALISE_QUERY_STRING:
      return {
        ...state,
        queryString: action.payload,
      };
    case actions.CHANGE_BRAND:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
