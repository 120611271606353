import { useEffect } from "react";
import { createPortal } from "react-dom";

export const Portal = ({parentId, useBody, children}) => {
  const mount = useBody ? document.body : document.getElementById(parentId);
  const el = document.createElement("div");

  useEffect(() => {
    mount.appendChild(el);
    return () => { mount.removeChild(el) };
  }, [el, mount]);

  return createPortal(children, el)
};
