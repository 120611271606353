import React from "react";
import cx from "classnames";
import Svg from "./spinner.style";
import PropTypes from "prop-types";

export const Spinner = ({ type, primaryCircle }) => (
  <Svg data-testid="loader" className="spinner-loader" viewBox="0 0 50 50">
    <circle
      className={cx("isoContentLoaderCircle", type === "light" ? "secondaryCircle" : "primaryCircle", primaryCircle === "goodCircle" ? "goodCircle" : "primaryCircle")}
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="3.6"
    />
  </Svg>
);

Spinner.propTypes = {
  type: PropTypes.string,
  primaryCircle: PropTypes.string
}
