export const handleExpandLabel = (
  side,
  apiLabelLeft,
  apiLabelRight,
  setIsLeftExpanded,
  setIsRightExpanded
) => {
  if (side === "left") {
    setIsLeftExpanded(true);
  } else {
    setIsRightExpanded(true);
  }
  const apiLabel = side === "left" ? apiLabelLeft : apiLabelRight;
  const translateValue = side === "left" ? "-250px" : "250px";
  apiLabel.start({
    from: {
      width: 0,
      transform: `translateX(${translateValue})`,
    },
    to: {
      width: 200,
      transform: `translateX(0px)`,
    },
  });
};

export const handleMinimiseLabel = (
  side,
  apiLabelLeft,
  apiLabelRight,
  setIsLeftExpanded,
  setIsRightExpanded
) => {
  if (side === "left") {
    setIsLeftExpanded(false);
  } else {
    setIsRightExpanded(false);
  }
  const apiLabel = side === "left" ? apiLabelLeft : apiLabelRight;
  const translateValue = side === "left" ? "-250px" : "250px";
  apiLabel.start({
    from: {
      width: 200,
      transform: "translateX(0px)",
    },
    to: {
      width: 0,
      transform: `translateX(${translateValue})`,
    },
  });
};
