import React from "react";
import MobileHeader from "./mobileHeader";
import Wrapper from "./mainHeader.styles";

const MainHeader = ({
  showSidebarLeft,
  setShowSidebarLeft,
  showSidebarRight,
  setShowSidebarRight,
  shouldShowHeader,
}) =>
  shouldShowHeader && (
    <Wrapper>
      <MobileHeader
        showSidebarLeft={showSidebarLeft}
        setShowSidebarLeft={setShowSidebarLeft}
        setShowSidebarRight={setShowSidebarRight}
        showSidebarRight={showSidebarRight}
      />
    </Wrapper>
  );

export default MainHeader;
