import styled, { css } from "styled-components";

export default styled.div(({ theme }) => css`
  display: none;
  height: 100vh;
  width: 100%;
  background: ${theme.palette.header.background};
  
  .sidebar {
    display: flex;
    flex-direction: column;
  }

  .right-nav-expand {
    background: transparent;
    width: 60px;
    border: none;
    padding: 10px;
    cursor: pointer;
    align-self: flex-end;
  }

  @media only screen and (min-width: ${theme.breakpoints.XM}) {
    display: block;
  }

  .link-container {
    p {
      margin-right: 10px;
    }

    svg {
      width: 25px;
    }
  }
`);
