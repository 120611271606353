import { all, call, put, takeLatest } from "redux-saga/effects";
import { prepareLoanForm } from "./reducer";
import actions from "./actions";
import { submitForm } from "../utility/shared";

function* loanForm(action) {
  const { payload } = action;
  const { values, endpoint } = payload;

  yield put(actions.postLoanFormStart());

  const loanFormPost = prepareLoanForm({ values });

  yield call(submitForm, endpoint, loanFormPost.values, actions);
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.MANAGE_REPAYMENT_POST_APPLICATION, loanForm),
  ]);
}