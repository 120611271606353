import VARIABLES from "./variables";

const buttons = {
  borderRadius: "999px",
  fontSize: "16px",
  fontWeight: "700",
  primary: {
    color:  VARIABLES.COLORS.white,
    colorHover: VARIABLES.COLORS.enchantedForestGreen,
    background: VARIABLES.COLORS.enchantedForestGreen,
    backgroundHover: VARIABLES.COLORS.mantisGreen,
    border: "none",
    padding: "15px 25px"
  },
  secondary: {
    color: VARIABLES.COLORS.white,
    colorHover: VARIABLES.COLORS.enchantedForestGreen,
    background: VARIABLES.COLORS.mantisGreen,
    backgroundHover: VARIABLES.COLORS.white,
    border: "none",
    padding: "15px 25px"
  },
  primaryAlt: {
    color: VARIABLES.COLORS.enchantedForestGreen,
    colorHover: VARIABLES.COLORS.white,
    background: VARIABLES.COLORS.white,
    backgroundHover: VARIABLES.COLORS.enchantedForestGreen,
    border: "none",
    padding: "15px 25px"
  },
  secondaryAlt: {
    color: VARIABLES.COLORS.white,
    colorHover: VARIABLES.COLORS.orange,
    background: VARIABLES.COLORS.orange,
    backgroundHover: VARIABLES.COLORS.white,
    border: "none",
    padding: "15px 25px"
  },
  disabled: {
    color: VARIABLES.COLORS.darkerGray,
    background: VARIABLES.COLORS.lightGray,
    border: "none",
    padding: "15px 25px"
  },
  link: {
    color: VARIABLES.COLORS.themeBody, // 0
    hoverColor: VARIABLES.COLORS.enchantedForestGreen // 1
  },
};

export { buttons };
