import React from "react";
import Wrapper from "./sidebarRightMobile.styles";
import { useSelector } from "react-redux";
import SidebarContent from "./sidebarContent";
import { APP_STATE } from "../../constants/commonConstants";
import Footer from "../footer/footer";

const SidebarRightMobile = ({
  showSidebarRight,
  shouldShowSidebarContent,
  setShowSidebarLeft,
  setShowSidebarRight,
  setShowNotifications
}) => {
  const { appState } = useSelector((state) => state.Client);
  const isSidebarReady = appState === APP_STATE.READY;

  return (
    <Wrapper className={isSidebarReady && showSidebarRight ? "show" : ""}>
      <div className="sidebar">
        <SidebarContent
          setShowNotifications={setShowNotifications}
          shouldShowSidebarContent={shouldShowSidebarContent}
          setShowSidebarLeft={setShowSidebarLeft}
          setShowSidebarRight={setShowSidebarRight}
          menuSide="right"
        />
        <div className="footer-group">
          <Footer />
        </div>
      </div>
    </Wrapper>
  );
};

export default SidebarRightMobile;
