import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import clientActions from "../../redux/client/actions";
import { useContext } from "../../utils/reactAdapter";
import { AuthContext } from "../../providers/authProvider";

const useAuthProfile = () => {
  const [profile, setProfile] = useState(() => ({}));
  const authContext = useContext(AuthContext);
  const { getUser } = authContext;
  
  useEffect(() => {
    const runGetUser = async () => {
      const user = await getUser();
      const {profile} = user || {};

      setProfile(profile);
    };
    
    runGetUser();
  }, [getUser, setProfile]);
  
  return profile;
};

function hasLoggedInUser(sub) {
  return !!sub;
}

const TriggerGet = () => {
  const dispatch = useDispatch();
  const profile = useAuthProfile();
  const { sub } = profile || {};
  
  useEffect(() => {
    if (hasLoggedInUser(sub)) {
      dispatch(clientActions.getClientData());
    }
  }, [sub, dispatch]);

  return null;
}

export default TriggerGet;
