export const PUBLIC_ROUTE = {
  INDEX: "/",
  PAGE_NOT_FOUND: "",
  LOG_OUT: "/logout",
};

export const PRIVATE_ROUTE = {
  LANDING: "/dashboard",
  PAYMENT: "/payment",
  EZIDEBIT: "/payment/pay-via-card",
  APPLY_PERSONAL_LOAN_APPLY: "/apply/pl-apply",
  APPLY_REFINANCE: "/apply/refinance",
  APPLY_LOC_APPLY: "/apply/loc-apply",
  APPLY_CAR_LOAN_APPLY: "/apply/cl-apply",
  APPLY_LOC_DRAWDOWN: "/apply/loc-drawdown",
  APPLY_TRY_LATER_PARTIAL_ROUTE: "/try-later",
  APPLY_NOW_MENU: "/apply-now",
  MANAGE_REPAYMENTS: "/manage-repayments",
  LOAN_HISTORY: "/loan-history",
  HELP: "/help",
};
