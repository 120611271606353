import { createEmptyAction, createPayloadAction } from "../utility/actions";

const GET_CLIENT_DATA_START = "GET_CLIENT_DATA_START";
const GET_CLIENT_DATA_FAILED = "GET_CLIENT_DATA_FAILED";
const GET_CLIENT_DATA_SUCCESS = "GET_CLIENT_DATA_SUCCESS";
const SET_ACCOUNT_ID = "SET_ACCOUNT_ID";
const SET_ARREARS_AMOUNT = "SET_ARREARS_AMOUNT";
const SET_INELIGIBLE = "SET_INELIGIBLE";
const GET_ACCOUNT_TRANSACTIONS_START = "GET_ACCOUNT_TRANSACTIONS_START";
const GET_ACCOUNT_TRANSACTIONS_SUCCESS = "GET_ACCOUNT_TRANSACTIONS_SUCCESS";
const GET_ACCOUNT_TRANSACTIONS_FAILED = "GET_ACCOUNT_TRANSACTIONS_FAILED";
const CLEAR_ACCOUNT_INFO = "CLEAR_ACCOUNT_INFO";
const SET_FULL_ARREARS = "SET_FULL_ARREARS";

const actions = {
  GET_CLIENT_DATA_START,
  GET_CLIENT_DATA_FAILED,
  GET_CLIENT_DATA_SUCCESS,
  SET_ACCOUNT_ID,
  SET_ARREARS_AMOUNT,
  SET_INELIGIBLE,
  GET_ACCOUNT_TRANSACTIONS_START,
  GET_ACCOUNT_TRANSACTIONS_SUCCESS,
  GET_ACCOUNT_TRANSACTIONS_FAILED,
  CLEAR_ACCOUNT_INFO,
  SET_FULL_ARREARS,
  setFullArrears: createEmptyAction(SET_FULL_ARREARS),
  clearAccountInfo: createEmptyAction(CLEAR_ACCOUNT_INFO),
  getClientData: createEmptyAction(GET_CLIENT_DATA_START),
  getClientDataFailed: createEmptyAction(GET_CLIENT_DATA_FAILED),
  getClientDataSuccess: createPayloadAction(GET_CLIENT_DATA_SUCCESS),
  setAccountId: createPayloadAction(SET_ACCOUNT_ID),
  setArrearsAmount: createPayloadAction(SET_ARREARS_AMOUNT),
  setIneligible: createPayloadAction(SET_INELIGIBLE),
  getTransactionsStart: createPayloadAction(GET_ACCOUNT_TRANSACTIONS_START),
  getTransactionsSuccess: createPayloadAction(GET_ACCOUNT_TRANSACTIONS_SUCCESS),
  getTransactionsFailed: createEmptyAction(GET_ACCOUNT_TRANSACTIONS_FAILED),
};

export default actions;
