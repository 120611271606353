import VARIABLES from "./variables";

const buttons = {
  borderRadius: "4px",
  fontSize: "16px",
  fontWeight: "600",
  primary: {
    color:  VARIABLES.COLORS.white,
    colorHover: VARIABLES.COLORS.white,
    background: VARIABLES.COLORS.darkGreen,
    backgroundHover: VARIABLES.COLORS.black,
    border: "none",
    padding: "11px 20px"
  },
  secondary: {
    color: VARIABLES.COLORS.white,
    colorHover: VARIABLES.COLORS.darkGreen,
    background: VARIABLES.COLORS.darkGreen,
    backgroundHover: VARIABLES.COLORS.white,
    border: "none",
    padding: "11px 20px"
  },
  primaryAlt: {
    color: VARIABLES.COLORS.darkGreen,
    colorHover: VARIABLES.COLORS.white,
    background: VARIABLES.COLORS.white,
    backgroundHover: VARIABLES.COLORS.darkGreen,
    border: "none",
    padding: "11px 20px"
  },
  secondaryAlt: {
    color: VARIABLES.COLORS.darkGreen,
    colorHover: VARIABLES.COLORS.white,
    background: "transparent",
    backgroundHover: VARIABLES.COLORS.darkGreen,
    border: `2px solid ${VARIABLES.COLORS.darkGreen}`,
    padding: "9px 18px"
  },
  disabled: {
    color: VARIABLES.COLORS.darkerGray,
    background: VARIABLES.COLORS.lightGray,
    border: "none",
    padding: "11px 20px"
  },
  link: {
    color: VARIABLES.COLORS.slate, // 0
    hoverColor: VARIABLES.COLORS.darkGreen // 1
  },
};

export { buttons };
