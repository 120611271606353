import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from './theme/global/globalStyles';
import store from './redux/store';
import Boot from './redux/boot';
import Routes from './routes';
import AppProvider from './AppProvider';
import { ModalParent } from "./components/modal";
import { BrowserRouter } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";

const App = () => {
  return (
    <Provider store={store}>
      <AppProvider>
        <GlobalStyles />
        <BrowserRouter>
          <CompatRouter>
            <Routes />
          </CompatRouter>
        </BrowserRouter>
        <ModalParent />
      </AppProvider>
    </Provider>
  );
};

Boot()
  .then(() => {})
  .catch((error) => console.error(error));

export default App;