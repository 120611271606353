import styled, { css } from "styled-components";

const Wrapper = styled.svg.attrs(({
  className: "spinner"
}))(({ theme }) => css`
  width: 40px;
  height: 40px;
  animation: svgSpinner 1.4s linear infinite;

  .isoContentLoaderCircle {
    animation: svgSpinnerCircle 1.4s ease-in-out infinite;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
    stroke-linecap: round;
  }

  .primaryCircle {
    stroke: ${theme.palette.main.primary};
  }

  .secondaryCircle {
    stroke: ${theme.palette.main.secondary};
  }

  .goodCircle {
    stroke: ${theme.palette.status.good};
  }

  @keyframes svgSpinner {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes svgSpinnerCircle {
    0% {
      stroke-dasharray: 1px, 200px;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -15px;
    }
    100% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -120px;
    }
  }
`);

export default Wrapper;