import { environmentSettings } from "../../constants/environmentSettings";

const { runConfiguration } = environmentSettings;

export const isDevelopment = () => {
  return runConfiguration === 'development'
}

export const isProduction = () => {
  return runConfiguration === 'production'
}