import { all, call, put, takeLatest } from "redux-saga/effects";
import { prepareLoanForm } from "./reducer";
import actions from "./actions";
import { submitForm } from "../utility/shared";

function* loanForm(action) {
  const { payload } = action;
  const { values, step, endpoint, submitFormStep } = payload;

  yield put(actions.pageSubmitted());


  if (!submitFormStep) {
    return;
  }

  yield put(actions.postLoanFormStart());

  const loanFormPost = prepareLoanForm({ values, step });

  yield call(submitForm, endpoint, loanFormPost.values, actions);
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.POST_APPLICATION, loanForm),
  ]);
}