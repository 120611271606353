// Note: only reference in theme files
const VARIABLES = Object.freeze({
  COLORS: {
    themeBody: "#336164",
    themeDisclaimer: "#a8a8a8",
    enchantedForestGreen: "#003a3d",
    oceanBlue: "#00646D",
    darkGreenEleven: "#488f74",
    mantisGreen: "#39D25D",
    teaGreen: "#D7F6DF",
    orange: "#FEAC01",
    salmon: "#DC4141",
    white: "#FFFFFF",
    black: "#000000",
    lighterGray: "#F5F5F5",
    lightGray: "#D8D8D8",
    gray: "#c1c1c1",
    mediumGray: "#aaaaaa",
    darkGray: "#808080",
    darkerGray: "#5E6267",
    lightFourteenGrayOpaque: "rgba(0, 0, 0, 0.8)"
  },
});

export default VARIABLES;
