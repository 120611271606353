import actions from "./actions";

export const prepareLoanForm = (values = {}) => {
  // sanitize
  return { ...values };
}

const initState = {
  pendingFields: {},
  initialValue: prepareLoanForm(),
  loanFormError: false,
  loanFormSuccessfullySubmitted: false,
  loanFormErrorMessage: "",
  isFetching: false,
  stepNavigation: -1,
  breadcrumbStep: 0,
  hasShownLoanFormError: false,
  hasFormStarted: false,
};

export default function (state = initState, action) {
  const { type } = action;
  switch (type) {
    case actions.MERGE_INITIAL_FIELD_VALUE:
      return {
        ...state,
        initialValue: prepareLoanForm({
          ...state.initialValue,
          ...action.payload,
        }),
      }
    case actions.REMOVE_PENDING_FIELD:
      const newPendingFields = { ...state.pendingFields };
      delete newPendingFields[action.payload];
      const pendingFields = state.pendingFields.hasOwnProperty(action.payload) ? newPendingFields : state.pendingFields;

      return {
        ...state,
        pendingFields,
      }

    case actions.PAGE_SUBMITTED:
      return {
        ...state,
        hasFormStarted: true,
      }

    case actions.POST_APPLICATION:
      return {
        ...state,
        initialValue: prepareLoanForm({
          ...state.initialValue,
          ...action.payload.values,
        }),
        stepNavigation: action.payload.step + 1,
      }
    case actions.SET_BREADCRUMB_STEP:
      return {
        ...state,
        stepNavigation: -1,
        breadcrumbStep: action.payload
      };

    case actions.POST_LOAN_FORM_START:
      return {
        ...state,
        isFetching: true,
        loanFormSuccessfullySubmitted: false,
        loanFormErrorMessage: "",
        loanFormError: false,
      };

    case actions.POST_LOAN_FORM_SUCCESS:
      return {
        ...state,
        loanFormSuccessfullySubmitted: true,
        isFetching: false,
      };

    case actions.POST_LOAN_FORM_ERROR:
      return {
        ...state,
        isFetching: false,
        loanFormErrorMessage: action.payload.errMessage,
        loanFormError: true,
        loanFormSuccessfullySubmitted: false,
        hasShownLoanFormError: true,
      };

    case actions.CLEAR_ERROR:
      return {
        ...state,
        loanFormErrorMessage: "",
        loanFormError: false,
      }

    case actions.RESET_FORM:
      return {
        ...initState,
        stepNavigation: 0,
      }
    default:
      return state;
  }
}
