import VARIABLES from "./variables";

const palette = {
  main: {
    primary: VARIABLES.COLORS.nimbleRed, // check mark 0, 2 |
    secondary: VARIABLES.COLORS.darkGreen, // adding this for green in Nimble theme, primary will be red
    white: VARIABLES.COLORS.white, // color 16 | formInputElement 5
    black: VARIABLES.COLORS.black, // color 21 |
    border: VARIABLES.COLORS.darkGreen,
    fieldWell: VARIABLES.COLORS.lightGreen, // bankStatementsPrompt 0 |
  },
  status: { // used for accent colors and status (ie arrears text)
    good: VARIABLES.COLORS.darkGreen, // vibrant 
    goodAlt: VARIABLES.COLORS.darkGreen, // dull
    bad: VARIABLES.COLORS.darkRed, // vibrant 
    badAlt: VARIABLES.COLORS.darkerRed, // dull
    medium: VARIABLES.COLORS.orange, // 0, 49, 62  
    hardship: VARIABLES.COLORS.darkerGray
  },
  grayscale: {
    lighterGray: VARIABLES.COLORS.lighterGray, // 5, 6, 7 lightGrayTwelve
    lightGray: VARIABLES.COLORS.lightGray, // 2 lightGrayFour
    gray: VARIABLES.COLORS.gray, // 1 lightGrayTwo
    mediumGray: VARIABLES.COLORS.slateLight, // 15 lightGraySeventeen
    darkGray: VARIABLES.COLORS.darkGray, // 17 gray
    darkerGray: VARIABLES.COLORS.darkerGray  // color 46 newDashboardDarkGrey
  },
  text: {
    heading: VARIABLES.COLORS.slate,
    body: VARIABLES.COLORS.slate,
    disclaimer: VARIABLES.COLORS.themeDisclaimer
  },
  header: {
    background: VARIABLES.COLORS.nimbleRed, // 0, 1
    color: VARIABLES.COLORS.whiteOpaque, // 2
    mobileColor: VARIABLES.COLORS.nimbleRed, // 3
    active: VARIABLES.COLORS.white,
    icon: VARIABLES.COLORS.whiteOpaque,
    iconActive: VARIABLES.COLORS.white
  },
  footer: {
    border: VARIABLES.COLORS.white, // 0: border top &* only used in frontend
    background: VARIABLES.COLORS.slate, // 1: BackGround &* only used in frontend
    color: VARIABLES.COLORS.white // 2: Text &* only used in frontend
  },
  modal: {
    background: VARIABLES.COLORS.darkerGray, 
    overlay: VARIABLES.COLORS.lightFourteenGrayOpaque, // 2
    accent: VARIABLES.COLORS.lightGreen, // 0
    header: VARIABLES.COLORS.darkGreen, // 3
  },
  input: {
    boxShadow: VARIABLES.COLORS.slateLight, // &* used by frontend
    valid: VARIABLES.COLORS.darkGreen, // 
    error: VARIABLES.COLORS.formError, // 
    label: VARIABLES.COLORS.black, // formInputElement 0, 8 
    border: VARIABLES.COLORS.darkGray, // formInputElement 1, 6 | border: 3
    borderAlt: VARIABLES.COLORS.darkGreen, // border: 5: extra light green &* only used in frontend
    placeholder: VARIABLES.COLORS.darkGray, // formInputElement 2 &* only used in frontend
    icon: VARIABLES.COLORS.darkGray, // 7: dollarsign &* only used in frontend
    checkbox: VARIABLES.COLORS.darkGreen
  },
  thankYou: {
    icon: VARIABLES.COLORS.slate, // 3
    envelop: VARIABLES.COLORS.darkGreen, // 6
    border: VARIABLES.COLORS.darkGreen, // 1 
    background: VARIABLES.COLORS.lightGreen, // 2
    title: VARIABLES.COLORS.slate, // 4
    skip: VARIABLES.COLORS.slate, // 5 
  },
};

export { palette };
