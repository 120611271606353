import { all, takeLatest, put } from "redux-saga/effects";
import actions from "./actions";
import { determineBrandAndBrandKey, getBrandFromHost } from "@theme/config";
import { nimbleFavicon } from "@config/nimble";

function* setInitialTheme() {
  const host = window.location.hostname.toLocaleLowerCase();
  const brandKeyFromHost = getBrandFromHost(host);
  const { brand, brandKey } = determineBrandAndBrandKey(brandKeyFromHost);
  if (brandKeyFromHost === 'nimbleBrand') {

    // favicon
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = nimbleFavicon;

    // meta description
    let meta = document.querySelector("meta[name~='description']");
    if (!meta) {
      meta = document.createElement('meta');
      meta.name = 'description';
      document.getElementsByTagName('head')[0].appendChild(meta);
    }
    meta.content = `${brand.configFile.companyName} | Customer Portal`;
  }
  yield put(actions.changeTheme({ brandKey, brand }));
}

export const __TEST__ = {
  setInitialTheme,
};

export default function* rootSaga() {
  yield all([
    takeLatest(actions.INITIALISE_QUERY_STRING, setInitialTheme)
  ]);
}
