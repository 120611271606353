/**
 * @description axios for fetching data via services
 */
 import axios from "axios";
 import { CONTENT_URL, CONTENT_TOKEN } from "../services/contentRoutes";
 
 const strapiApi = axios.create({
   baseURL: CONTENT_URL,
   timeout: 300000, // 5 min
   headers: {
     Authorization: `Bearer ${CONTENT_TOKEN}`,
   },
 });
 
 export default strapiApi;