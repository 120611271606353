import { useContext } from "../../utils/reactAdapter";
import { useOnMount } from "../../hooks";
import { AuthContext } from "../../providers/authProvider";

export const useAuthMountCallback = (callback, secondsDelay = 0) => {
  const authContext = useContext(AuthContext);
  
  useOnMount(() => {
    setTimeout(() => {
      callback(authContext);
    }, secondsDelay * 1000)
  });
};