import styled, { css } from "styled-components";

export default styled.div(({ theme }) => css`
  text-align: left;
  flex: 1;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .copyright-container-desktop {
    display: none;
  }

  .lock-container-desktop {
    display: none;
  }

  p {
    font-size: 11px;
    color: ${theme.palette.main.white};
  }

  .copyright-container {
    padding-left: 10px;

    @media only screen and (min-width: ${theme.breakpoints.S}) {
      padding-left: 40px;
    }
  }

  .lock-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 10px;

    @media only screen and (min-width: ${theme.breakpoints.S}) {
      padding-left: 40px;
    }
  }

  .lock-container p {
    margin-top: 5px;
  }

  @media only screen and (min-width: ${theme.breakpoints.XM}) {
    .lock-container, .copyright-container {
      display: none;
    }

    .lock-container-desktop {
      margin-top: 5px;
    }

    .copyright-container-desktop, .lock-container-desktop {
      display: block;
      padding-left: 20px;
    }
  }
`);
