import React from "react";
import PageWrapper from "../../containers/PageWrapper";
import Paper from "../paper";
import ContentWrapper from "../contentWrapper";
import styled, { css } from "styled-components";

const Wrapper = styled.div(({ theme }) => css`
  h1 {
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: -0.2px;
    color: ${theme.palette.text.heading};
    text-align: center;
    margin-bottom: 16px;
  }
  a {
    display: block;
    text-align: center;
    color: ${theme.buttons.link.color};
  }
`);

export const LoggedOut = () => (
  <PageWrapper
    shouldShowSidebarContent={false}
    shouldShowHeader={false}
    shouldShowMobileFooter={false}
  >
    <Paper className="page-wrapper page-wrapper-padded">
      <ContentWrapper>
        <Wrapper>
          <h1>Logged Out!</h1>
          <a href="/">Home</a>
        </Wrapper>
      </ContentWrapper>
    </Paper>
  </PageWrapper>
);
