import { all, takeLatest, call, put } from "redux-saga/effects";
import actions from "./actions";
import { getAccountTransactions, getClientData } from "../../services/clientApi";

function* getClientDataCall() {
  try {
    const response = yield call(getClientData);
    const { data } = response;
    const { code, value } = data;
    if (code === 200) {
      yield put(actions.getClientDataSuccess(value));
    } else {
      yield put(actions.getClientDataFailed());
    }
  } catch (e) {
    yield put(actions.getClientDataFailed());
  }
}

function* getAccountTransactionsCall({ payload: accountNumber }) {
  try {
    const response = yield call(getAccountTransactions, accountNumber);
    const { data, status } = response;
    const { value } = data || {};
    const { success, accountTransactions } = value;
    if (status === 200 && success) {
      yield put(actions.getTransactionsSuccess({ accountNumber, transactions: accountTransactions }));
    } else {
      yield put(actions.getTransactionsFailed());
    }
  } catch (e) {
    yield put(actions.getTransactionsFailed());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_CLIENT_DATA_START, getClientDataCall),
    takeLatest(actions.GET_ACCOUNT_TRANSACTIONS_START, getAccountTransactionsCall),
  ]);
}
