import actions from "./actions";
import { APP_STATE } from "../../constants/commonConstants";

const initState = {
  helpData: {},
  helpContentState: "",
  bannerData: {},
  bannerDataState: "",
  accountNotificationData: {},
  accountNotificationDataState: "",
};

export default function (state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case actions.GET_HELP_CONTENT_DATA_START:
      return {
        ...state,
        helpContentState: APP_STATE.LOADING,
      };
    case actions.GET_HELP_CONTENT_DATA_FAILED:
      return {
        ...state,
        helpContentState: APP_STATE.ERROR,
      };
    case actions.GET_HELP_CONTENT_DATA_SUCCESS:
      return {
        ...state,
        helpContentState: APP_STATE.READY,
        helpData: payload,
      };
    case actions.GET_BANNER_CONTENT_DATA_START:
      return {
        ...state,
        bannerDataState: APP_STATE.LOADING,
      };
    case actions.GET_BANNER_CONTENT_DATA_FAILED:
      return {
        ...state,
        bannerDataState: APP_STATE.ERROR,
      };
    case actions.GET_BANNER_CONTENT_DATA_SUCCESS:
      return {
        ...state,
        bannerDataState: APP_STATE.READY,
        bannerData: payload,
      };
    case actions.GET_ACCOUNT_NOTIFICATIONS_DATA_START:
      return {
        ...state,
        accountNotificationDataState: APP_STATE.LOADING,
      };
    case actions.GET_ACCOUNT_NOTIFICATIONS_DATA_FAILED:
      return {
        ...state,
        accountNotificationDataState: APP_STATE.ERROR,
      };
    case actions.GET_ACCOUNT_NOTIFICATIONS_DATA_SUCCESS:
      return {
        ...state,
        accountNotificationDataState: APP_STATE.READY,
        accountNotificationData: payload,
      };
    default:
      return state;
  }
}
