import styled, { css } from "styled-components";

export const Paper = styled.div(({ theme }) => css`
  width: 100%;
  box-sizing: border-box;

  @media only screen and (min-width: ${theme.breakpoints.XM}) {
    width: 100%;
    padding: 0;
  }
`);

export const PaddedPaper = styled(Paper)`
  padding: 20px;
`;

export const PaddedPaperDashboard = styled(Paper)(({ theme }) => css`
  padding: 0;

  .desktop-view-accounts {
    display: none;

    @media only screen and (min-width: ${theme.breakpoints.XXM}) {
      display: block;
    }
  }

  .mobile-view-accounts {
    display: block;

    @media only screen and (min-width: ${theme.breakpoints.XXM}) {
      display: none;
    }
  }
`);
