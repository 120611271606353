const filterOnEmptyString = (str) => str.trim() !== "";

export const parseQueryString = (search) => {
  const chunkedSearch = 
    search
      .slice(search.indexOf('?') + 1)
      .split('&')
      .filter(filterOnEmptyString);
  
  return chunkedSearch.reduce((accumulator, chunk) => {
    const [key, val] = chunk.split('=');
    accumulator[decodeURIComponent(key)] = decodeURIComponent(val);

    return accumulator;
  }, {});
};

export const combineHashSearchQueryString = location => {
  return {...parseQueryString(location.search), ...parseQueryString(location.hash)};
};

export const removeSearch = pathname => {
  const safePathname = pathname ?? "";
  return safePathname.substring(0, safePathname.indexOf("?"));
};

export const stringifyQueryStringObject = queryString => {
  return Object.entries(queryString).reduce((accumulator, [key, value]) => {
    const normalisedValue = value || "";
    const joinString = accumulator.length !== 0 ? "&" : "";
    const prefix = accumulator === "" ? "?" : accumulator;
    return `${prefix}${joinString}${key}=${normalisedValue}`
  }, "");
};