import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "@redux-devtools/extension";
import { createRootReducer } from "./root-reducer";
import rootSaga from "./root-saga";
import { createDevActionsMiddleware } from "./devActionsMiddleware";
import { isProduction } from './../utils/helpers/environment';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const bindMiddleware = middleware => {
  if (!isProduction()) {
    const devActionsMiddleware = createDevActionsMiddleware();
    return composeWithDevTools(applyMiddleware(...middleware, devActionsMiddleware));
  }
  return applyMiddleware(...middleware);
};

const store = createStore(
  createRootReducer(),
  bindMiddleware(middlewares)
);

sagaMiddleware.run(rootSaga);

export default store;