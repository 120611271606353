import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "./sidebarContent.styles";
import { NavLink, useLocation } from "react-router-dom";
import { APP_STATE } from "../../constants/commonConstants";
import actions from "../../redux/client/actions";
import { useSpring, animated } from "@react-spring/web";
import { menuListLeft, menuListRight } from "./sidebarMenuItems";
import {
  handleExpandLabel,
  handleMinimiseLabel,
} from "./sidebarContentAnimate";
import { ReactComponent as DashboardIcon } from "../../theme/assets/newDashboardIcons/ico-navigation-dashboard.svg";
import { ReactComponent as HistoryIcon } from "../../theme/assets/newDashboardIcons/ico-navigation-history.svg";
import { ReactComponent as HelpIcon } from "../../theme/assets/newDashboardIcons/ico-navigation-help.svg";
import { ReactComponent as ApplyIcon } from "../../theme/assets/newDashboardIcons/ico-navigation-apply-now.svg";
import { ReactComponent as ManageRepaymentsIcon } from "../../theme/assets/newDashboardIcons/ico-navigation-manage-repayments.svg";
import { ReactComponent as PayOnlineIcon } from "../../theme/assets/newDashboardIcons/ico-navigation-PayOnline.svg";
import { ReactComponent as LogoutIcon } from "../../theme/assets/newDashboardIcons/ico-navigation-LogOut.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faEnvelopeOpen
} from "@fortawesome/free-solid-svg-icons";
import { useTheme } from 'styled-components';

const useAppReady = () => {
  const { appState } = useSelector((state) => state.Client);

  return appState === APP_STATE.READY;
};

const useHasRepaymentsToManage = () => {
  const appReady = useAppReady();
  const { clientData } = useSelector((state) => state.Client);
  const { openAccounts } = clientData;

  return appReady && openAccounts.length !== 0;
};

const DashboardIconSvg = (navItem) => {
  const theme = useTheme();
  switch (navItem) {
    case "Dashboard":
      return <DashboardIcon className="nav-svg" />;
    case "Loan History":
      return <HistoryIcon className="nav-svg" />;
    case "Help":
      return <HelpIcon className="nav-svg" />;
    case "Apply":
      return <ApplyIcon className="nav-svg" />;
    case "Manage Repayments":
      return <ManageRepaymentsIcon className="nav-svg" />;
    case "Pay Online":
      return <PayOnlineIcon className="nav-svg" />;
    case "Logout":
      return <LogoutIcon className="nav-svg" />;
    case "Notifications":
      return sessionStorage.getItem("show_notifications") === "true" ? <FontAwesomeIcon className="nav-svg" icon={faEnvelopeOpen} color={theme.palette.header.active}/> : <FontAwesomeIcon className="nav-svg" icon={faEnvelope} color={theme.palette.header.active}/>;
    default:
      return <></>;
  }
};

const SidebarContent = ({
  shouldShowSidebarContent,
  menuSide,
  isExpandedLeftNav,
  isExpandedRightNav,
  setShowSidebarLeft,
  setShowSidebarRight,
  setShowNotifications,
}) => {
  const isManageRepaymentsEnabled = useHasRepaymentsToManage();
  const appReady = useAppReady();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const getSessionStorageValueLeft =
    sessionStorage.getItem("expandedStatusLeft") === "true" ? true : false;
  const [isLeftExpanded, setIsLeftExpanded] = useState(
    getSessionStorageValueLeft
  );

  const getSessionStorageValueRight =
    sessionStorage.getItem("expandedStatusRight") === "true" ? true : false;
  const [isRightExpanded, setIsRightExpanded] = useState(
    getSessionStorageValueRight
  );

  const [springsLabelLeft, apiLabelLeft] = useSpring(() => ({
    from: {
      width: isExpandedLeftNav ? 200 : 0,
      transform: isExpandedLeftNav ? "translateX(0px)" : "translateX(-250px)",
    },
  }));

  const [springsLabelRight, apiLabelRight] = useSpring(() => ({
    from: {
      width: isExpandedRightNav ? 200 : 0,
      transform: isExpandedRightNav ? "translateX(0px)" : "translateX(250px)",
    },
  }));

  const menuList =
    menuSide === "left"
      ? menuListLeft(isManageRepaymentsEnabled)
      : menuListRight;

  const springsLabel =
    menuSide === "left" ? springsLabelLeft : springsLabelRight;

  useEffect(() => {
    if (isExpandedRightNav === true) {
      if (isRightExpanded === true) {
        return;
      } else {
        handleExpandLabel(
          menuSide,
          apiLabelLeft,
          apiLabelRight,
          setIsLeftExpanded,
          setIsRightExpanded
        );
      }
    }

    if (isExpandedRightNav === false) {
      if (isRightExpanded) {
        handleMinimiseLabel(
          menuSide,
          apiLabelLeft,
          apiLabelRight,
          setIsLeftExpanded,
          setIsRightExpanded
        );
      } else return;
    }
  }, [
    isExpandedRightNav,
    isRightExpanded,
    menuSide,
    apiLabelLeft,
    apiLabelRight,
    setIsLeftExpanded,
    setIsRightExpanded,
  ]);

  useEffect(() => {
    if (isExpandedLeftNav === true) {
      if (isLeftExpanded === true) {
        return;
      } else {
        handleExpandLabel(
          menuSide,
          apiLabelLeft,
          apiLabelRight,
          setIsLeftExpanded,
          setIsRightExpanded
        );
      }
    }

    if (isExpandedLeftNav === false) {
      if (isLeftExpanded) {
        handleMinimiseLabel(
          menuSide,
          apiLabelLeft,
          apiLabelRight,
          setIsLeftExpanded,
          setIsRightExpanded
        );
      } else return;
    }
  }, [
    isExpandedLeftNav,
    isLeftExpanded,
    menuSide,
    apiLabelLeft,
    apiLabelRight,
    setIsLeftExpanded,
    setIsRightExpanded,
  ]);

  const handleClickNavigationItem = (link, side) => {
    if (link !== pathname) {
      dispatch(actions.clearAccountInfo());
    } else {
      if (side === "left") {
        try {
          setShowSidebarLeft(false);
        } catch {
          return;
        }
      }
      if (side === "right") {
        try {
          setShowSidebarRight(false);
        } catch {
          return;
        }
      }
    }
  };

  const handleNotALink = (e, side) => {
    if (pathname === "/dashboard") {
      e.preventDefault();

      const notificationState = sessionStorage.getItem("show_notifications");
      if (notificationState === "true") {
        setShowNotifications("false");
        sessionStorage.setItem("show_notifications", "false");
      } else {
        setShowNotifications("true");
        sessionStorage.setItem("show_notifications", "true");
      }

      if (side === "left") {
        try {
          setShowSidebarLeft(false);
        } catch {
          return;
        }
      }
      if (side === "right") {
        try {
          setShowSidebarRight(false);
        } catch {
          return;
        }
      }
    } else {
      sessionStorage.setItem("show_notifications", "true");
    }
  };

  return (
    <Wrapper justifyAttribute={menuSide}>
      {shouldShowSidebarContent && (
        <>
          {appReady &&
            menuList
              .filter((item) => !item.disabled)
              .map((eachItem, index) => (
                <NavLink
                  key={index}
                  to={`${eachItem.link}`}
                  className="link-container"
                  activeClassName="active"
                  onClick={
                    eachItem.label !== "Notifications"
                      ? () =>
                          handleClickNavigationItem(
                            eachItem.link,
                            eachItem.side
                          )
                      : (e) => handleNotALink(e, eachItem.side)
                  }
                >
                  {DashboardIconSvg(eachItem.label)}
                  <animated.div
                    className="label-desktop"
                    style={{ ...springsLabel }}
                  >
                    <p>{eachItem.label}</p>
                  </animated.div>
                  <p className="label-mobile">{eachItem.label}</p>
                </NavLink>
              ))}
        </>
      )}
    </Wrapper>
  );
};

export default SidebarContent;
