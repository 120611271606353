import { call, put } from "redux-saga/effects";
import { postLoanForm } from "../../services/applicationFormApi";
import { WENT_WRONG_MESSAGE } from "../../constants/commonMessage";

export function* submitForm(endpoint, loanFormValues, actions) {
  try {
    const response = yield call(postLoanForm, endpoint, loanFormValues);
    const { status } = response;

    switch (status) {
      case 200:
        yield put(actions.postLoanFormSuccess());
        break;
      default: {
        yield put(
          actions.postLoanFormError({ errMessage: WENT_WRONG_MESSAGE })
        );
      }
    }
  } catch (error) {
    yield put(
      actions.postLoanFormError({
        errMessage: error.message || WENT_WRONG_MESSAGE
      })
    );
  }
}