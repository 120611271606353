import { createEmptyAction, createPayloadAction } from "../utility/actions";

const POST_APPLICATION = "POST_APPLICATION";
const MERGE_INITIAL_FIELD_VALUE = "MERGE_INITIAL_FIELD_VALUE";
const REMOVE_PENDING_FIELD = "REMOVE_PENDING_FIELD";
const RESET_FORM = "RESET_FORM";
const SET_BREADCRUMB_STEP = "SET_BREADCRUMB_STEP";
const POST_LOAN_FORM_START = "POST_LOAN_FORM_START";
const POST_LOAN_FORM_SUCCESS = "POST_LOAN_FORM_SUCCESS";
const POST_LOAN_FORM_ERROR = "POST_LOAN_FORM_ERROR";
const CLEAR_ERROR = "CLEAR_ERROR";
const PAGE_SUBMITTED = "PAGE_SUBMITTED";

const actions = {
  POST_APPLICATION,
  MERGE_INITIAL_FIELD_VALUE,
  REMOVE_PENDING_FIELD,
  RESET_FORM,
  SET_BREADCRUMB_STEP,
  POST_LOAN_FORM_START,
  POST_LOAN_FORM_SUCCESS,
  POST_LOAN_FORM_ERROR,
  CLEAR_ERROR,
  PAGE_SUBMITTED,
  postRequest: createPayloadAction(POST_APPLICATION),
  postLoanFormStart: createEmptyAction(POST_LOAN_FORM_START),
  postLoanFormSuccess: createEmptyAction(POST_LOAN_FORM_SUCCESS),
  postLoanFormError: createPayloadAction(POST_LOAN_FORM_ERROR),
  setFieldInitialValue: createPayloadAction(MERGE_INITIAL_FIELD_VALUE),
  removePendingField: createPayloadAction(REMOVE_PENDING_FIELD),
  resetForm: createEmptyAction(RESET_FORM),
  setBreadcrumbStep: createPayloadAction(SET_BREADCRUMB_STEP),
  clearError: createEmptyAction(CLEAR_ERROR),
  pageSubmitted: createEmptyAction(PAGE_SUBMITTED),
};

export default actions;
