import actions from "./actions";

export const prepareLoanForm = (values = {}) => {
  // sanitize
  return { ...values };
}

const initState = {
  pendingFields: {},
  initialValue: prepareLoanForm(),
  loanFormError: false,
  loanFormSuccessfullySubmitted: false,
  loanFormErrorMessage: "",
  isFetching: false,
  hasShownLoanFormError: false,
  hasFormStarted: false,
  isFormValidated: false,
};

export default function (state = initState, action) {
  const { type } = action;
  switch (type) {
    case actions.MANAGE_REPAYMENT_MERGE_INITIAL_FIELD_VALUE:
      return {
        ...state,
        initialValue: prepareLoanForm({
          ...state.initialValue,
          ...action.payload,
        }),
      }
    case actions.MANAGE_REPAYMENT_REMOVE_PENDING_FIELD:
      const newPendingFields = { ...state.pendingFields };
      delete newPendingFields[action.payload];
      const pendingFields = state.pendingFields.hasOwnProperty(action.payload) ? newPendingFields : state.pendingFields;

      return {
        ...state,
        pendingFields,
      }

    case actions.MANAGE_REPAYMENT_POST_APPLICATION:
      return {
        ...state,
        initialValue: prepareLoanForm({
          ...state.initialValue,
          ...action.payload.values,
        }),
      }

    case actions.MANAGE_REPAYMENT_VALIDATE_FORM: //MANAGE_REPAYMENT_VALIDATE_FORM and MANAGE_REPAYMENT_INVALIDATE_FORM exists so we can check if the payment amount input is valid, and show different button text if it is.
      return {
        ...state,
        isFormValidated: true,
      }

    case actions.MANAGE_REPAYMENT_INVALIDATE_FORM:
      return {
        ...state,
        isFormValidated: false,
      }
    
    case actions.MANAGE_REPAYMENT_POST_LOAN_FORM_START:
      return {
        ...state,
        isFetching: true,
        loanFormSuccessfullySubmitted: false,
        loanFormErrorMessage: "",
        loanFormError: false,
      };

    case actions.MANAGE_REPAYMENT_POST_LOAN_FORM_SUCCESS:
      return {
        ...state,
        loanFormSuccessfullySubmitted: true,
        isFetching: false,
      };

    case actions.MANAGE_REPAYMENT_POST_LOAN_FORM_ERROR:
      return {
        ...state,
        isFetching: false,
        loanFormErrorMessage: action.payload.errMessage,
        loanFormError: true,
        loanFormSuccessfullySubmitted: false,
        hasShownLoanFormError: true,
      };

    case actions.MANAGE_REPAYMENT_CLEAR_ERROR:
      return {
        ...state,
        loanFormErrorMessage: "",
        loanFormError: false,
      }

    case actions.MANAGE_REPAYMENT_RESET_FORM:
      return {
        ...initState,
      }
    default:
      return state;
  }
}
