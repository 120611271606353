import { createEmptyAction, createPayloadAction } from "../utility/actions";

const MANAGE_REPAYMENT_POST_APPLICATION = "MANAGE_REPAYMENT_POST_APPLICATION";
const MANAGE_REPAYMENT_MERGE_INITIAL_FIELD_VALUE = "MANAGE_REPAYMENT_MERGE_INITIAL_FIELD_VALUE";
const MANAGE_REPAYMENT_REMOVE_PENDING_FIELD = "MANAGE_REPAYMENT_REMOVE_PENDING_FIELD";
const MANAGE_REPAYMENT_RESET_FORM = "MANAGE_REPAYMENT_RESET_FORM";
const MANAGE_REPAYMENT_POST_LOAN_FORM_START = "MANAGE_REPAYMENT_POST_LOAN_FORM_START";
const MANAGE_REPAYMENT_POST_LOAN_FORM_SUCCESS = "MANAGE_REPAYMENT_POST_LOAN_FORM_SUCCESS";
const MANAGE_REPAYMENT_POST_LOAN_FORM_ERROR = "MANAGE_REPAYMENT_POST_LOAN_FORM_ERROR";
const MANAGE_REPAYMENT_CLEAR_ERROR = "MANAGE_REPAYMENT_CLEAR_ERROR";
const MANAGE_REPAYMENT_VALIDATE_FORM = "MANAGE_REPAYMENT_VALIDATE_FORM";
const MANAGE_REPAYMENT_INVALIDATE_FORM = "MANAGE_REPAYMENT_INVALIDATE_FORM";

const actions = {
  MANAGE_REPAYMENT_POST_APPLICATION,
  MANAGE_REPAYMENT_MERGE_INITIAL_FIELD_VALUE,
  MANAGE_REPAYMENT_REMOVE_PENDING_FIELD,
  MANAGE_REPAYMENT_RESET_FORM,
  MANAGE_REPAYMENT_POST_LOAN_FORM_START,
  MANAGE_REPAYMENT_POST_LOAN_FORM_SUCCESS,
  MANAGE_REPAYMENT_POST_LOAN_FORM_ERROR,
  MANAGE_REPAYMENT_CLEAR_ERROR,
  MANAGE_REPAYMENT_VALIDATE_FORM,
  MANAGE_REPAYMENT_INVALIDATE_FORM,
  markFormAsValid: createEmptyAction(MANAGE_REPAYMENT_VALIDATE_FORM),
  markFormAsInvalid: createEmptyAction(MANAGE_REPAYMENT_INVALIDATE_FORM),
  postRequest: createPayloadAction(MANAGE_REPAYMENT_POST_APPLICATION),
  postLoanFormStart: createEmptyAction(MANAGE_REPAYMENT_POST_LOAN_FORM_START),
  postLoanFormSuccess: createEmptyAction(MANAGE_REPAYMENT_POST_LOAN_FORM_SUCCESS),
  postLoanFormError: createPayloadAction(MANAGE_REPAYMENT_POST_LOAN_FORM_ERROR),
  setFieldInitialValue: createPayloadAction(MANAGE_REPAYMENT_MERGE_INITIAL_FIELD_VALUE),
  removePendingField: createPayloadAction(MANAGE_REPAYMENT_REMOVE_PENDING_FIELD),
  resetForm: createEmptyAction(MANAGE_REPAYMENT_RESET_FORM),
  clearError: createEmptyAction(MANAGE_REPAYMENT_CLEAR_ERROR),
};

export default actions;
