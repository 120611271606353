import styled, { css } from "styled-components";

export default styled.div(({ theme }) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;  
  width: 100%;
  height: 60px;
  background: ${theme.palette.header.background};
  position: sticky;
  top: 0;
  z-index: 1;

  @media only screen and (min-width: ${theme.breakpoints.XM}) {
    display: none;
  }
`);
