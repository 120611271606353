import styled, { css } from "styled-components";
import { isIE11 } from "../../utils/helpers/browserHelper";

const Wrapper = styled.div(({ theme }) => css`
  .modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    z-index: 999999;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    padding-top: 100px;
    padding-bottom: 50px;

    @media only screen and (max-width: ${theme.breakpoints.M}) {
      padding-top: 20px;
      padding-bottom: 75px;
    }

    transition: background-color 0.3s ease;
    background-color: rgba(0, 0, 0, 0);

    &.ReactModal__Overlay--after-open {
      background-color: ${theme.palette.modal.overlay};
    }

    &.is-closing {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .modal-container {
    display: flex;
  }

  .modal-container, .modal-content {
    max-height: 100%;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: ${theme.palette.main.white};
    text-align: left;
    font-size: ${theme.fonts.sizes.p5};

    @media only screen and (max-width: ${theme.breakpoints.M}) {
      max-width: calc(100vw - 40px) !important;
    }

    ${isIE11 && `
      max-height: calc(100vh - 100px);
    `}
    &.extra-small {
      max-width: 358px;
      /* IE11 max-width px fix */
      flex: 1 0 358px;
    }

    &.small {
      max-width: 500px;
      /* IE11 max-width px fix */
      flex: 1 0 500px;
    }

    &.medium {
      max-width: 60vw;
      width: 100%;
    }

    &.large {
      max-width: 90vw;
      width: 100%;
    }

    p {
      margin-top: ${theme.fonts.sizes.p5};
      margin-bottom: ${theme.fonts.sizes.p5};
    }
  }

  .modal-Body {
    overflow: auto;
    padding: 8px 16px;
    color: ${theme.palette.grayscale.gray};
  }

  .modal-Header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: left;
    background: ${theme.palette.modal.header};
    color: ${theme.palette.main.white};
    padding: 20px;
  }

  .modal-Title {
    display: block;
    width: 90%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    text-transform: ${theme.fonts.headingCase};
  }

  .modal-empty-div {
    width: 5%;
  }

  .modal-Footer {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    background-color: ${theme.palette.main.white};
    color: black;
    padding: 12px 16px 14px;
  }

  .modal-Close-Icon {
    align-self: center;
    background: none;
    border: none;
    color: ${theme.palette.main.white};
    float: right;
    font-size: 24px;
    font-weight: ${theme.fonts.headingWeight};
    width: 5%;
  }

  .modal-Close-Icon:hover,
  .modal-Close-Icon:focus {
    color: ${theme.palette.main.black};
    cursor: pointer;
  }
`);

export default Wrapper;
