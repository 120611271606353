import { getDaysInMonth } from "date-fns";
import { DAYS } from "../../constants/options";

export const currentYear = new Date().getFullYear();

export const getListOfDaysInMonth = (month, year) => {
  const activeDays = [...DAYS];
  const maxDaysInMonth = 31;
  const daysInMonth = getDaysInMonth(new Date(year, month - 1));
  const deleteCount = maxDaysInMonth - daysInMonth;
  const start = maxDaysInMonth - deleteCount;
  
  activeDays.splice(start, deleteCount);
  
  return activeDays;
};

export const wipeValueIfDayLargerThanMonthsAllowed = (day, month, year, wipeValue) => {
  const daysInMonth = getDaysInMonth(new Date(year, month - 1));
  if (day && day * 1 > daysInMonth) {
    wipeValue();
  }
};

const numberToStringOrEmptyString = (value) => {
  return isNaN(value) ? "" : value.toString() ;
}

export const parseIntoDateObject = (date) => {
  return { 
    day: numberToStringOrEmptyString(date.getDate()),
    month: numberToStringOrEmptyString(date.getMonth() + 1),
    year: numberToStringOrEmptyString(date.getFullYear()),
  };
};