import React, { useState } from "react";
import Wrapper from "./pageWrapper.styles";
import SidebarMobile from "../../components/sidebar";
import MainHeader from "../../components/mainHeader";
import SidebarRightDesktop from "../../components/sidebar/sidebarRightDesktop";
import SidebarLeftDesktop from "../../components/sidebar/sidebarLeftDesktop";

export const PageWrapper = ({
  children,
  shouldShowSidebarContent,
  shouldShowHeader,
  setShowNotifications
}) => {
  const [showSidebarLeft, setShowSidebarLeft] = useState(false);
  const [showSidebarRight, setShowSidebarRight] = useState(false);
  
  return (
    <Wrapper>
      <SidebarLeftDesktop/>
      <div className="dashboard-content-group">
        <SidebarMobile
          setShowNotifications={setShowNotifications}
          showSidebarLeft={showSidebarLeft}
          showSidebarRight={showSidebarRight}
          setShowSidebarLeft={setShowSidebarLeft}
          setShowSidebarRight={setShowSidebarRight}
          shouldShowSidebarContent={
            shouldShowSidebarContent !== undefined
              ? shouldShowSidebarContent
              : true
          }
        />
        <MainHeader
          showSidebarLeft={showSidebarLeft}
          showSidebarRight={showSidebarRight}
          setShowSidebarLeft={setShowSidebarLeft}
          setShowSidebarRight={setShowSidebarRight}
          shouldShowHeader={
            shouldShowHeader !== undefined ? shouldShowHeader : true
          }
        />
        {children}
      </div>
      <SidebarRightDesktop setShowNotifications={setShowNotifications}/>
    </Wrapper>
  );
};
