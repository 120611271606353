import React from "react";
import { useSelector } from "react-redux";
import Wrapper from "./footer.styles";
import { currentYear } from "../../utils/helpers/dateHelper";

const Footer = () => {
  const config = useSelector((state) => state.StartUp.brand.configFile);

  const { companyName, acn, acl } = config;

  return (
    <Wrapper>
      <div className="copyright-container">
        <p>
          © 2012-{currentYear} {companyName} &#x2022; ACN {acn} | ACL {acl}
        </p>
      </div>
      <div className="copyright-container-desktop">
        <p>
          © 2012-{currentYear} {companyName} <br></br> ACN {acn} | ACL {acl}
        </p>
      </div>
      <div className="lock-container">
        <p>
          <span>Secure &amp; Private</span> &#x2022; Your info is safe with us
        </p>
      </div>
      <div className="lock-container-desktop">
        <p>
          <span>Secure &amp; Private</span> <br></br> Your info is safe with us
        </p>
      </div>
    </Wrapper>
  );
};

export default Footer;
