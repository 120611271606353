import ReactModal from "react-modal";
import React from "react";
import Wrapper from "./modal.styles";
import { Portal } from "./portal";

ReactModal.setAppElement("#root");

export const ModalParent = () => {

  return (
    <Portal useBody>
      <Wrapper id="modal-parent"/>
    </Portal>
  );
};