import React, { useState } from "react";
import authService from "../../services/authService";
import { AuthContext } from "./authContext";

export const AuthProvider = ({children}) => {
  const [auth] = useState(() => new authService());

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}