import { domainSpecificConfig } from "../config/domainSpecificConfig";
import { getBrandFromHost } from "@theme/config/theme.config";
import { getEnvironmentType } from "../utils/helpers/getEnvironmentType";

const config = window.__PORTAL__.config;

const host = window.location.hostname.toLowerCase();
const brandKeyFromHost = getBrandFromHost(host);

const environmentType = getEnvironmentType(brandKeyFromHost);

const runConfiguration = config.REACT_APP_ENVIRONMENT_RUN_CONFIGURATION ? config.REACT_APP_ENVIRONMENT_RUN_CONFIGURATION : 'development';

export const environmentSettings = {
  runConfiguration,
  ...domainSpecificConfig[runConfiguration][environmentType]
};
