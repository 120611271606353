import { johnAccountKey } from "../constants";
import {
  DECREASE_PAYMENT_STATES,
  INCREASE_PAYMENT_STATES,
} from "../../../constants/commonConstants";

export const createJohnClientReplacer = (optionalDispatchIntercept) => {
  optionalDispatchIntercept(johnAccountKey, {
    type: "GET_CLIENT_DATA_SUCCESS",
    payload: {
      clientDetails: {
        firstName: "John",
        lastName: "Doe",
        mobile: "0415665654",
        emailAddress: "john.doe@test.com",
        clientId: "C125053",
        unitNumber: "",
        streetNumber: "111",
        suburb: "North Melbourne",
        street: "Canning St",
        state: "VIC",
        postCode: "3051",
        businessName: "X Corp",
        employerPhone: "1800000000",
        employmentBasis: "Full Time Work",
        dateStarted: {
          day: 1,
          month: 1,
          year: 2009,
        },
        numberOfDependents: "0",
      },
      openAccounts: [
        {
          percentRepaid: 0.7723719695381821,
          monitorCategoryA: "",
          availableCredit: -814.51,
          accountNumber: "622529",
          accountType: "MACC",
          accountDescription: "PL",
          accountPurpose: "Wedding Expenses",
          netBalance: 1052.29,
          contractedPaymentRegular: 131.87,
          minimumPaymentValue: 0,
          openedDate: {
            day: 18,
            month: 2,
            year: 2021,
          },
          dateQuoted: {
            day: 18,
            month: 2,
            year: 2021,
          },
          nextPaymentDate: { day: 13, month: 6, year: 2023 },
          arrearsValue: -2631.87,
          nextPaymentAmount: 250,
          loanAmountAdvanced: 3000,
          loanAmountRefinance: 0,
          payFrequency: "f",
          hasPendingTransaction: false,
          increaseRepayments: INCREASE_PAYMENT_STATES.SCHEDULE_MORE_THAN_TWO,
          decreaseRepayments:
            DECREASE_PAYMENT_STATES.REDIRECT_MANAGE_REPAYMENTS,
          arrears: "",
          termCycle: "471d",
          paymentPercentages: {
            repaid: 100,
            unpaid: 0,
            arrears: 0,
            scheduledPayment: 0,
            totalPercentage: 100,
          },

          scheduledPayments: [
            {
              date: { day: 2, month: 5, year: 2023 },
              balance: 5500,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 16, month: 5, year: 2023 },
              balance: 5350,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 30, month: 5, year: 2023 },
              balance: 5200,
              status: "Repaid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 13, month: 6, year: 2023 },
              balance: 4900,
              status: "Due",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 27, month: 6, year: 2023 },
              balance: 4900,
              status: "Future",
              amount: 0,
              isPaymentPromise: true,
            },
            {
              date: { day: 10, month: 7, year: 2023 },
              balance: 4920,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 24, month: 7, year: 2023 },
              balance: 4500,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 8, month: 8, year: 2023 },
              balance: 4350,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 22, month: 8, year: 2023 },
              balance: 4300,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 5, month: 9, year: 2023 },
              balance: 4200,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 19, month: 9, year: 2023 },
              balance: 4100,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 2, month: 10, year: 2023 },
              balance: 3950,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 16, month: 10, year: 2023 },
              balance: 3800,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 30, month: 10, year: 2023 },
              balance: 3550,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 14, month: 11, year: 2023 },
              balance: 3320,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 28, month: 11, year: 2023 },
              balance: 3100,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 12, month: 12, year: 2023 },
              balance: 2950,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 26, month: 12, year: 2023 },
              balance: 2700,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 9, month: 1, year: 2024 },
              balance: 2550,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 23, month: 1, year: 2024 },
              balance: 2250,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 7, month: 2, year: 2024 },
              balance: 2100,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 21, month: 2, year: 2024 },
              balance: 1900,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 7, month: 3, year: 2024 },
              balance: 1850,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 21, month: 3, year: 2024 },
              balance: 1750,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 4, month: 4, year: 2024 },
              balance: 1600,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 28, month: 4, year: 2024 },
              balance: 1500,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
          ],
        },
        {
          percentRepaid: 0.7723719695381821,
          monitorCategoryA: "PREHSHIP",
          availableCredit: -814.51,
          accountNumber: "622528",
          accountType: "LACC",
          accountDescription: "CL",
          accountPurpose: "Vehicle Purchase",
          netBalance: 6000.29,
          contractedPaymentRegular: 131.87,
          minimumPaymentValue: 0,
          openedDate: {
            day: 18,
            month: 2,
            year: 2021,
          },
          dateQuoted: {
            day: 18,
            month: 2,
            year: 2021,
          },
          nextPaymentDate: { day: 21, month: 3, year: 2024 },
          arrearsValue: -0,
          nextPaymentAmount: 250,
          loanAmountAdvanced: 3000,
          loanAmountRefinance: 0,
          payFrequency: "f",
          hasPendingTransaction: false,
          increaseRepayments: INCREASE_PAYMENT_STATES.SCHEDULE_MORE_THAN_TWO,
          decreaseRepayments:
            DECREASE_PAYMENT_STATES.SHOW_CONTACT_US,
          arrears: "",
          termCycle: "471d",
          paymentPercentages: {
            repaid: 0,
            unpaid: 70,
            arrears: 0,
            scheduledPayment: 30,
            totalPercentage: 100,
          },

          scheduledPayments: [
            {
              date: { day: 2, month: 5, year: 2023 },
              balance: 5500,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 16, month: 5, year: 2023 },
              balance: 5350,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 30, month: 5, year: 2023 },
              balance: 5200,
              status: "Repaid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 13, month: 6, year: 2023 },
              balance: 4900,
              status: "Repaid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 27, month: 6, year: 2023 },
              balance: 4900,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 10, month: 7, year: 2023 },
              balance: 4920,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 24, month: 7, year: 2023 },
              balance: 4500,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 8, month: 8, year: 2023 },
              balance: 4350,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 22, month: 8, year: 2023 },
              balance: 4300,
              status: "Repaid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 5, month: 9, year: 2023 },
              balance: 4200,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 19, month: 9, year: 2023 },
              balance: 4100,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 2, month: 10, year: 2023 },
              balance: 3950,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 16, month: 10, year: 2023 },
              balance: 3800,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 30, month: 10, year: 2023 },
              balance: 3550,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 14, month: 11, year: 2023 },
              balance: 3320,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 28, month: 11, year: 2023 },
              balance: 3100,
              status: "Repaid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 12, month: 12, year: 2023 },
              balance: 2950,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 26, month: 12, year: 2023 },
              balance: 2700,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 9, month: 1, year: 2024 },
              balance: 2550,
              status: "Repaid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 23, month: 1, year: 2024 },
              balance: 2250,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 7, month: 2, year: 2024 },
              balance: 2100,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 21, month: 2, year: 2024 },
              balance: 1900,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 7, month: 3, year: 2024 },
              balance: 1850,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 21, month: 3, year: 2024 },
              balance: 1750,
              status: "Due",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 4, month: 4, year: 2024 },
              balance: 1600,
              status: "Future",
              amount: 0,
              isPaymentPromise: true,
            },
            {
              date: { day: 28, month: 4, year: 2024 },
              balance: 1500,
              status: "Future",
              amount: 0,
              isPaymentPromise: true,
            },
          ],
        },
        {
          percentRepaid: 0.7723719695381821,
          monitorCategoryA: "",
          availableCredit: 500.29,
          accountNumber: "622527",
          accountType: "LOC",
          accountDescription: "LOC",
          accountPurpose: "Emergency Funds",
          netBalance: 500.29,
          contractedPaymentRegular: 131.87,
          minimumPaymentValue: 0,
          openedDate: {
            day: 18,
            month: 2,
            year: 2021,
          },
          dateQuoted: {
            day: 18,
            month: 2,
            year: 2021,
          },
          nextPaymentDate: { day: 5, month: 9, year: 2023 },
          arrearsValue: 30,
          nextPaymentAmount: 250,
          loanAmountAdvanced: 1200,
          loanAmountRefinance: 0,
          payFrequency: "f",
          hasPendingTransaction: false,
          increaseRepayments: INCREASE_PAYMENT_STATES.SCHEDULE_MORE_THAN_TWO,
          decreaseRepayments:
            DECREASE_PAYMENT_STATES.REDIRECT_MANAGE_REPAYMENTS,
          arrears: "arrears-loc",
          termCycle: "471d",
          paymentPercentages: {
            repaid: 50,
            unpaid: 30,
            arrears: 10,
            scheduledPayment: 10,
            totalPercentage: 100,
          },
          scheduledPayments: [
            {
              date: { day: 2, month: 5, year: 2023 },
              balance: 5500,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 16, month: 5, year: 2023 },
              balance: 5350,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 30, month: 5, year: 2023 },
              balance: 5200,
              status: "Repaid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 13, month: 6, year: 2023 },
              balance: 4900,
              status: "Missed",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 27, month: 6, year: 2023 },
              balance: 4900,
              status: "Missed",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 10, month: 7, year: 2023 },
              balance: 4920,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 24, month: 7, year: 2023 },
              balance: 4500,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 8, month: 8, year: 2023 },
              balance: 4350,
              status: "Paid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 22, month: 8, year: 2023 },
              balance: 4300,
              status: "Repaid",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 5, month: 9, year: 2023 },
              balance: 4200,
              status: "Due",
              amount: 0,
              isPaymentPromise: true,
            },
            {
              date: { day: 19, month: 9, year: 2023 },
              balance: 4100,
              status: "Future",
              amount: 0,
              isPaymentPromise: true,
            },
            {
              date: { day: 2, month: 10, year: 2023 },
              balance: 3950,
              status: "Future",
              amount: 0,
              isPaymentPromise: true,
            },
            {
              date: { day: 16, month: 10, year: 2023 },
              balance: 3800,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 30, month: 10, year: 2023 },
              balance: 3550,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 14, month: 11, year: 2023 },
              balance: 3320,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 28, month: 11, year: 2023 },
              balance: 3100,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 12, month: 12, year: 2023 },
              balance: 2950,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 26, month: 12, year: 2023 },
              balance: 2700,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 9, month: 1, year: 2024 },
              balance: 2550,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 23, month: 1, year: 2024 },
              balance: 2250,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 7, month: 2, year: 2024 },
              balance: 2100,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 21, month: 2, year: 2024 },
              balance: 1900,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 7, month: 3, year: 2024 },
              balance: 1850,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 21, month: 3, year: 2024 },
              balance: 1750,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 4, month: 4, year: 2024 },
              balance: 1600,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
            {
              date: { day: 28, month: 4, year: 2024 },
              balance: 1500,
              status: "Future",
              amount: 0,
              isPaymentPromise: false,
            },
          ],
        },
      ],
      quoteAccounts: [],
      closedAccounts: [],
      clientNotifications: ["account-transaction-activity", "preferences-update"],
      dashboardMessage: {
        dashboardPrimaryMessageModel: {
          banner: "Hi John,",
          desktopContent:
            "In the coming months our clever tech team will deliver more functionality so you can manage your account. So watch this space!",
          mobileContent:
            "In the coming months our clever tech team will deliver more functionality so you can manage your account. So watch this space!",
          icon: "SmilingFace",
          theme: "ComfortGreen",
        },
        dashboardPriorityPrimaryMessageModel: {
          banner: "",
          subBanner: "",
          desktopContent: "",
          mobileContent: "",
          expiry: "",
          icon: "",
        },
        dashboardSecondaryMessageModel: {
          banner: "Combination of Reasons",
          desktopContent:
            'Your current loan request has unfortunately been unsuccessful due to a few reasons including your bank account conduct. To help improve your chances for approval in the future, <a href="https://www.fairgofinance.com.au/goforwardwithus5" target="_blank">here is a link to some helpful information.</a> We will also be in touch over the coming months with more helpful tips that we hope can help you go forward, financially',
          mobileContent:
            'Your current loan request has unfortunately been unsuccessful due to a few reasons including your bank account conduct. <a href="https://www.fairgofinance.com.au/goforwardwithus5" target="_blank">Here is a link to some helpful information</a> and we\'ll keep in touch with more',
          reapply:
            "Please wait -357 days before re-applying (this can help protect your credit rating too.)",
          show: false,
          icon: "Information",
        },
      },
      creditScores: [
        {
          score: 539,
          date: {
            day: 18,
            month: 2,
            year: 2021,
          },
        },
      ],
      hasHadFundedAccount: true,
      eligibilityResult: {
        applyNewAutoLoan: {
          isEligible: true,
        },
        applyNewLineOfCredit: {
          isEligible: true,
        },
        applyNewPersonalLoan: {
          isEligible: true,
        },
        applyTopUpPersonalLoan: {
          isEligible: true,
        },
        drawDownLineOfCredit: {
          isEligible: true,
        },
        lineOfCreditLimitIncrease: {
          isEligible: true,
        },
      },
    },
  });
};
