const blankClient = {
  clientDetails: {
    firstName: "",
    lastName: "",
    emailAddress: "",
    mobile: "",
  },
  openAccounts: [],
  creditScores: [],
  tailoredMessage: {
    bannerMessage: null,
    desktopMessage: null,
    mobileMessage: null,
  },
  eligibilityResult: {
    "applyTopUpPersonalLoan":
        {
            "isEligible": false
        },
    "applyNewPersonalLoan":
        {
            "isEligible": false
        },
    "applyNewLineOfCredit":
        {
            "isEligible": false
        },
    "drawDownLineOfCredit":
        {
            "isEligible": false
        },
    "lineOfCreditLimitIncrease":
        {
            "isEligible": false
        },
    "applyNewAutoLoan":
        {
            "isEligible": false
        }
  }
};
export default blankClient;