import { combineReducers } from "redux";
import StartUp from "./startup/reducer";
import Client from "./client/reducer";
import applicationForm from "./applicationForm/reducer";
import manageRepaymentsForm from "./manageRepaymentsForm/reducer";
import ContentDelivery from "./contentDelivery/reducer";

export const createRootReducer = () => combineReducers({
  StartUp, // todo: make this not capitalised
  Client, // todo: make this not capitalised
  applicationForm,
  manageRepaymentsForm,
  ContentDelivery,
});
