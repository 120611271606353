import { useAuthMountCallback } from "../../hooks/auth";
import React from "react";
import PageWrapper from "../../containers/PageWrapper";
import Loader from "../loader";

export const RedirectToSignin = (props) => {
  useAuthMountCallback((authContext) => authContext.signinRedirect(), 1);

  return (
    <PageWrapper
      shouldShowSidebarContent={false}
      shouldShowHeader={false}
      shouldShowMobileFooter={false}
    >
      <Loader message="redirecting you" />
    </PageWrapper>
  );
};
