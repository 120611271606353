import { borderRadii } from './borderRadii';
import { palette } from './palette';
import { projectSpecific } from './projectSpecific';
import { fonts } from './fonts';
import { zIndexes } from '../allThemes/zIndexes';
import { breakpoints } from '../allThemes/breakpoints';
import { buttons } from './buttons';

const defaultTheme = {
  palette,
  fonts,
  borderRadii,
  projectSpecific,
  buttons,
  zIndexes,
  breakpoints
};

// for intellisense (assuming Default and Nimble theme structure stays the same) 
type ThemeInterface = typeof defaultTheme;

declare module "styled-components" {
  interface DefaultTheme extends ThemeInterface {}
}

export default defaultTheme;
