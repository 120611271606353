import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isUndefined } from "lodash";
import actions from "../redux/applicationForm/actions";

const pendingFieldsSelector = state => state.applicationForm.pendingFields;

const usePendingPrefill = (fieldName) => {
  const dispatch = useDispatch();
  const pendingFields = useSelector(pendingFieldsSelector);
  const pendingField = pendingFields[fieldName];
  const shouldDispatchPendingField = !isUndefined(pendingField);
  
  useEffect(() => {
    if (shouldDispatchPendingField) {
      dispatch(actions.setFieldInitialValue({[fieldName]: pendingField}));
      dispatch(actions.removePendingField(fieldName));
    }
  }, [dispatch, fieldName, pendingField, shouldDispatchPendingField])
};

export default usePendingPrefill;