import { IDENTITY_CONFIG, METADATA_OIDC } from "../constants/authConst";
import { environmentSettings } from '../constants/environmentSettings';
import { Log, UserManager, WebStorageStateStore } from "oidc-client";
import { getWindow } from "../utils/windowAdapter";
import { getLocalStorageItem, getSessionStorageItem, setLocalStorageItem } from "../utils/localStorageAdapter";
import { safeRedirectHome } from "./safeRedirectHome";

const { authClientId, authUrl, portalDebug } = environmentSettings;

const redirectUriKey = "redirectUri";

export default class authService {
    UserManager;

    constructor() {
        this.UserManager = new UserManager({
            ...IDENTITY_CONFIG,
            userStore: new WebStorageStateStore({ store: window.sessionStorage }),
            metadata: {
                ...METADATA_OIDC
            }
        });

        // Logger
        Log.logger = console;
        Log.level = portalDebug ? Log.INFO : Log.NONE;
        this.UserManager.events.addUserLoaded((user) => {
            if (window.location.href.indexOf("signin-oidc") !== -1) {
                this.navigateToScreen();
            }
        });

        this.UserManager.events.addSilentRenewError((e) => {
            console.log("silent renew error", e.message);
        });

        this.UserManager.events.addAccessTokenExpired(() => {
            this.signinSilent();
        });
    }

    signinRedirectCallback = () => {
        (async () => {
            try {
                await this.UserManager.signinRedirectCallback()
            }
            catch (err) {
                safeRedirectHome();
            }
        })();
    };


    getUser = async () => {
        const user = await this.UserManager.getUser();
        if (!user) {
            return {};
        }
        return user;
    };
    
    signinRedirect = () => {
        const { location } = getWindow();
        const locationUrl = new URL(location.href);
        const usernameParam = locationUrl.searchParams.get("username");

        let extraQueryParams = {};
        if (usernameParam) {
            extraQueryParams["username"] = usernameParam;
            locationUrl.searchParams.delete("username");
        }

        setLocalStorageItem(redirectUriKey, locationUrl.pathname + locationUrl.search + locationUrl.hash);
        if (this.UserManager.settings) {
            this.UserManager.settings.extraQueryParams = extraQueryParams;
        }
        this.UserManager.signinRedirect(); 
    };


    navigateToScreen = () => {
        const redirectUri = getLocalStorageItem(redirectUriKey);
        window.location.replace(redirectUri ?? "/");
    };


    isAuthenticated = () => {
        const oidcStorage = JSON.parse(getSessionStorageItem(`oidc.user:${authUrl}:${authClientId}`));
        return !!oidcStorage && !!oidcStorage.id_token;
    };

    signinSilent = () => {
        return this.UserManager.signinSilent()
            .catch((err) => {
                console.log(err);
            });
    };
    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };

    createSigninRequest = () => {
        return this.UserManager.createSigninRequest();
    };

    logout = async () => {
        const { id_token } = await this.getUser();
        await this.UserManager.signoutRedirect({
            id_token_hint: id_token,
        });
        await this.UserManager.clearStaleState(); 
    };

    signoutRedirectCallback = async () => {
        const { location } = getWindow();
        await this.UserManager.signoutRedirectCallback();
        await this.UserManager.clearStaleState();
        location.replace(IDENTITY_CONFIG.logout);
    };
}