import { borderRadii } from './borderRadii';
import { palette } from './palette';
import { projectSpecific } from './projectSpecific';
import { fonts } from './fonts';
import { zIndexes } from '../allThemes/zIndexes';
import { breakpoints } from '../allThemes/breakpoints';
import { buttons } from './buttons';

const nimbleTheme = {
  palette,
  fonts,
  borderRadii,
  projectSpecific,
  buttons,
  zIndexes,
  breakpoints
};

export default nimbleTheme;
