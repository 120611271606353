/**
 * @description Common Saga combined
 */

import { all } from "redux-saga/effects";
import startUpSagas from "../redux/startup/sagas";
import clientSagas from "../redux/client/sagas";
import applicationFormSagas from "../redux/applicationForm/sagas";
import manageRepaymentsFormSagas from "../redux/manageRepaymentsForm/sagas";
import contentDeliverySagas from "../redux/contentDelivery/sagas";

export default function* rootSaga(getState) {
  yield all([
    startUpSagas(getState), 
    clientSagas(getState),
    applicationFormSagas(getState),
    manageRepaymentsFormSagas(getState),
    contentDeliverySagas(getState),
  ]);
}
