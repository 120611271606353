import React, { lazy, Suspense, useEffect, useMemo } from "react";
import { Switch } from "react-router-dom";
import {
  CompatRoute,
  useSearchParams,
  useLocation,
  Navigate,
} from "react-router-dom-v5-compat";
import { useSelector } from "react-redux";
import ErrorBoundary from "./ErrorBoundary";
import { PRIVATE_ROUTE, PUBLIC_ROUTE } from "./route.constants";
import Loader, { LoadingStubber } from "./components/loader";
import { Callback } from "./components/auth/callback";
import { LoggedOut } from "./components/auth/loggedOut";
import { Logout } from "./components/auth/logout";
import { LogoutCallback } from "./components/auth/logoutCallback";
import { SilentRenew } from "./components/auth/silentRenew";
import { PrivateRoute } from "./components/routes";
import TriggerGet from "./components/triggerGet/TriggerGet";
import FourOhFour from "./containers/FourOhFour";

const privateRoutes = [
  {
    path: PRIVATE_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import("./containers/Dashboard")),
  },
  {
    path: PRIVATE_ROUTE.PAYMENT,
    exact: true,
    component: lazy(() => import("./containers/Payment")),
  },
  {
    path: PRIVATE_ROUTE.EZIDEBIT,
    exact: true,
    component: lazy(() => import("./containers/EziDebitForm")),
  },
  {
    path: PRIVATE_ROUTE.APPLY_PERSONAL_LOAN_APPLY,
    exact: false,
    component: lazy(() => import("./containers/Forms/containers/ApplyPL")),
  },
  {
    path: PRIVATE_ROUTE.APPLY_REFINANCE,
    exact: false,
    component: lazy(() => import("./containers/Forms/containers/ApplyTopUp")),
  },
  {
    path: PRIVATE_ROUTE.APPLY_LOC_APPLY,
    exact: false,
    component: lazy(() => import("./containers/Forms/containers/ApplyLoc")),
  },
  {
    path: PRIVATE_ROUTE.APPLY_CAR_LOAN_APPLY,
    exact: false,
    component: lazy(() => import("./containers/Forms/containers/ApplyCL")),
  },
  {
    path: PRIVATE_ROUTE.APPLY_LOC_DRAWDOWN,
    exact: false,
    component: lazy(() =>
      import("./containers/Forms/containers/ApplyLocDrawDown")),
  },
  {
    path: PRIVATE_ROUTE.APPLY_NOW_MENU,
    exact: false,
    component: lazy(() => import("./containers/ApplyNowMenu")),
  },
  {
    path: PRIVATE_ROUTE.MANAGE_REPAYMENTS,
    exact: false,
    component: lazy(() => import("./containers/ManageRepayments")),
  },
  {
    path: PRIVATE_ROUTE.LOAN_HISTORY,
    exact: false,
    component: lazy(() => import("./containers/LoanHistory")),
  },
  {
    path: PRIVATE_ROUTE.HELP,
    exact: false,
    component: lazy(() => import("./containers/HelpSection")),
  },
];

export default function Routes() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { queryString } = useSelector((state) => state.StartUp);
  const location = useLocation();

  const cachedSearchParamsValue = useMemo(() => {
    const utmTagKeys = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
    ];

    let searchParamObject = {};
    for (const [key, value] of Object.entries(queryString)) {
      if (utmTagKeys.some((item) => item === key)) {
        searchParamObject[key] = value;
      }
    }
    return searchParamObject;
  }, [queryString]);

  useEffect(() => {
    const existingParams = Object.fromEntries([...searchParams]);
    if (location.pathname !== PUBLIC_ROUTE.INDEX) {
      setSearchParams({ ...existingParams, ...cachedSearchParamsValue });
    }
  }, [
    setSearchParams,
    cachedSearchParamsValue,
    location.pathname,
    searchParams,
  ]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Switch>
          <CompatRoute exact={true} path="/signin-oidc" component={Callback} />
          <CompatRoute exact={true} path="/logged-out" component={LoggedOut} />
          <CompatRoute
            exact={true}
            path={PUBLIC_ROUTE.LOG_OUT}
            component={Logout}
          />
          <CompatRoute
            exact={true}
            path="/logout/callback"
            component={LogoutCallback}
          />
          <CompatRoute
            exact={true}
            path="/silent-renew"
            component={SilentRenew}
          />
          <CompatRoute path={PUBLIC_ROUTE.INDEX} exact={true}>
            <Navigate to={PRIVATE_ROUTE.LANDING + location.search} replace={true} />
          </CompatRoute>

            {privateRoutes.map((route, index) => (
              <PrivateRoute key={index} path={route.path} exact={route.exact}>
                <LoadingStubber>
                  <route.component />
                </LoadingStubber>
              </PrivateRoute>
            ))}
            <CompatRoute path="*" component={FourOhFour} />
          </Switch>
          <TriggerGet />
      </Suspense>
    </ErrorBoundary>
  );
}