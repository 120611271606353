import styled, { css } from "styled-components";

const Wrapper = styled.div(({ theme }) => css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  z-index: 10000000000;
  top: 0;
  right: 0;

  @media only screen and (min-width: 768px) and (max-width: 1220px) {
    width: calc(100% - 80px);
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }

  .message {
    font-size: 16px;
    font-weight: ${theme.fonts.headingWeight};
    padding-bottom: 8px;
    color: ${theme.palette.text.body};
  }
`);

export default Wrapper;
