import { useEffect, useState } from "react";
import { isUndefined } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import actions from "../redux/applicationForm/actions";

export default (fieldName, defaultValue = "", isLoading = false) => {
  const dispatch = useDispatch();
  const [hasSetDefault, setHasSetDefault] = useState(false);
  const { initialValue } = useSelector(state => state.applicationForm);
  const value = initialValue[fieldName]

  useEffect(() => {
    const hasNoValue = isUndefined(value) || value === null;

    if (!hasSetDefault && !isLoading && hasNoValue) {
      dispatch(actions.setFieldInitialValue({[fieldName]: defaultValue}));
      setHasSetDefault(true);
    }
  }, [fieldName, value, defaultValue, hasSetDefault, setHasSetDefault, dispatch, isLoading]);
};