import { getUnixTime } from "date-fns";
import { getNow } from "../../constants/now";

const createTokenFromUser = (user) => {
  const { access_token, id_token } = user;
  return { access_token, id_token };
}

const hasTokenExpired = (user) => {
  const { expires_at: expiresAt } = user;
  const nowEpoch = getUnixTime(getNow());

  return nowEpoch > expiresAt;
}

export const getTokens = async ({ getUser, signinSilent } = {}) => {
  const user = await getUser();

  if (hasTokenExpired(user)) {
    const newUser = await signinSilent();
    return createTokenFromUser(newUser);
  }

  return createTokenFromUser(user);
};