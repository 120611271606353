const signingHasAttemptedRedirectKey = "signingHasAttemptedRedirect";

const redirectHome = () => {
  window.localStorage.setItem(signingHasAttemptedRedirectKey, new Date());
  window.location = "/";
};

// look for a key in localStorage and parse it's date.
// if the date is from over 1 min ago OR there is no key
// redirect to home and set the key so that we don't
// get stuck in a redirect loop
export const safeRedirectHome = () => {
  const signInRedirectAttempted = window.localStorage.getItem(signingHasAttemptedRedirectKey);
  if (signInRedirectAttempted !== null) {
    const signInRedirectAttemptedTime = Date.parse(signInRedirectAttempted);

    const minAgeBeforeCanRedirectTicks = (new Date()).getTime() - (60 * 1000); // 1 min expiry
    if (signInRedirectAttemptedTime < minAgeBeforeCanRedirectTicks) { // if the sign in happened over 1 min ago
      redirectHome();
    }
  } else {
    redirectHome();
  }
};