import React from "react";
import { useAuthMountCallback } from "../../hooks/auth";
import PageWrapper from "../../containers/PageWrapper";
import Loader from "../loader";
import { REDIRECT_DELAY_SECONDS } from "../../constants/commonConstants";

export const LogoutCallback = () => {
  useAuthMountCallback(
    (authService) => authService.signoutRedirectCallback(),
    REDIRECT_DELAY_SECONDS.STANDARD
  );

  return (
    <PageWrapper
      shouldShowSidebarContent={false}
      shouldShowHeader={false}
      shouldShowMobileFooter={false}
    >
      <Loader message="redirecting you" />
    </PageWrapper>
  );
};
