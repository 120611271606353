import { bootstrapWindowsTools } from "./bootstrapWindowsTools";
import { bootstrapReplacers } from "./bootstrapReplacers";

export const createDevActionsMiddleware = () => {
  bootstrapWindowsTools();
  const replacements = bootstrapReplacers();

  return (storeAPI) => (next) => (action) => {
    const { type } = action;

    if (replacements.hasOwnProperty(type)) {
      const replacementAction = replacements[type];

      console.log(`Found ${type} replacement via window.setDevToolsEnabled({${type}: true});, disable with window.setDevToolsEnabled({${type}: false});`);
      console.log("actual action: ", action);
      console.log("replaced with: ", replacementAction);

      return next(replacementAction);
    }

    return next(action);
  }
};