import { createEmptyAction, createPayloadAction } from "../utility/actions";
import { parseQueryString as queryStringParser } from "../../services/parseQueryString";

const START_UP = "START_UP";
const INITIALISE_QUERY_STRING = "INITIALISE_QUERY_STRING";
const CHANGE_BRAND="CHANGE_BRAND";
const FILL_FORM="FILL_FORM";
const GET_LOAN_REASONS_START="GET_LOAN_REASONS_START";
const parseQueryString = () => {
  return {
    type: INITIALISE_QUERY_STRING,
    payload: queryStringParser(window.location.search || "")
  };
};

const actions = {
  START_UP,
  INITIALISE_QUERY_STRING,
  CHANGE_BRAND,
  FILL_FORM,
  GET_LOAN_REASONS_START,
  startUp: createPayloadAction(START_UP),
  getLoanReasonsRequest: createEmptyAction(GET_LOAN_REASONS_START),
  parseQueryString: parseQueryString,
  changeTheme: createPayloadAction(CHANGE_BRAND),
  fillForm: createEmptyAction(FILL_FORM)
};

export default actions;
