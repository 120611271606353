import React, { useEffect, useState } from "react";
import Loader from "../loader";
import PageWrapper from "../../containers/PageWrapper";
import { useAuthMountCallback } from "../../hooks/auth";
import { REDIRECT_DELAY_SECONDS } from "../../constants/commonConstants";

const hangInThereDelay = 3000;
const tryReloadingDelay = hangInThereDelay + 7000;

const useLoaderMessage = () => {
  const [loaderMessage, setLoaderMessage] = useState(<span>redirecting you</span>);

  useEffect(() => {
    const hangInThereTimeoutId = setTimeout(() => {
      setLoaderMessage(<span>hang in there, almost complete</span>);
    }, hangInThereDelay);

    const tryReloadingTimeoutId = setTimeout(() => {
      setLoaderMessage(<span>try <a href="/" id="callback-user-reload-button">reloading</a></span>);
    }, tryReloadingDelay);

    return () => {
      clearTimeout(hangInThereTimeoutId);
      clearTimeout(tryReloadingTimeoutId);
    }
  }, [setLoaderMessage]);

  return loaderMessage;
};

export const Callback = () => {
  const loaderMessage = useLoaderMessage();
  useAuthMountCallback(
    (authService) => authService.signinRedirectCallback(),
    REDIRECT_DELAY_SECONDS.STANDARD
  );

  return (
    <PageWrapper
      shouldShowSidebarContent={false}
      shouldShowHeader={false}
      shouldShowMobileFooter={false}
    >
      <Loader message={loaderMessage} />
    </PageWrapper>
  );
};
