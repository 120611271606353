import React from "react";
import { useSelector } from "react-redux";
import Loader from "./index";
import { APP_STATE } from "../../constants/commonConstants";
import { loadingBrand } from "@theme/config";

export const LoadingStubber = ({children}) => {
  const themeName = useSelector(state => state.StartUp.brand.themeName);
  const { appState } = useSelector(state => state.Client);

  if (themeName === loadingBrand.themeName || appState === APP_STATE.LOADING) {
    return <Loader />;
  }

  return <>{children}</>;
};