import { BrandKeys } from "@theme/config/theme.config";

const environmentTypes = {
  FGF: 'FGF',
  NIM: 'NIM'
} as const;

export const getEnvironmentType = (brandKeyFromHost: BrandKeys) => {
  switch(brandKeyFromHost) {
    case 'defaultBrand':
      return environmentTypes.FGF;
    case 'nimbleBrand':
      return environmentTypes.NIM;
    default:
      return environmentTypes.FGF;
  }
}

