import { createEmptyAction, createPayloadAction } from "../utility/actions";

const GET_HELP_CONTENT_DATA_START = "GET_HELP_CONTENT_DATA_START";
const GET_HELP_CONTENT_DATA_FAILED = "GET_HELP_CONTENT_DATA_FAILED";
const GET_HELP_CONTENT_DATA_SUCCESS = "GET_HELP_CONTENT_DATA_SUCCESS";
const GET_BANNER_CONTENT_DATA_START = "GET_BANNER_CONTENT_DATA_START";
const GET_BANNER_CONTENT_DATA_FAILED = "GET_BANNER_CONTENT_DATA_FAILED";
const GET_BANNER_CONTENT_DATA_SUCCESS = "GET_BANNER_CONTENT_DATA_SUCCESS";
const GET_ACCOUNT_NOTIFICATIONS_DATA_START = "GET_ACCOUNT_NOTIFICATIONS_DATA_START";
const GET_ACCOUNT_NOTIFICATIONS_DATA_FAILED = "GET_ACCOUNT_NOTIFICATIONS_DATA_FAILED";
const GET_ACCOUNT_NOTIFICATIONS_DATA_SUCCESS = "GET_ACCOUNT_NOTIFICATIONS_DATA_SUCCESS";

const actions = {
  GET_HELP_CONTENT_DATA_START,
  GET_HELP_CONTENT_DATA_FAILED,
  GET_HELP_CONTENT_DATA_SUCCESS,
  GET_BANNER_CONTENT_DATA_START,
  GET_BANNER_CONTENT_DATA_FAILED,
  GET_BANNER_CONTENT_DATA_SUCCESS,
  GET_ACCOUNT_NOTIFICATIONS_DATA_START,
  GET_ACCOUNT_NOTIFICATIONS_DATA_FAILED,
  GET_ACCOUNT_NOTIFICATIONS_DATA_SUCCESS,
  getHelpContentData: createEmptyAction(GET_HELP_CONTENT_DATA_START),
  getHelpContentDataFailed: createEmptyAction(GET_HELP_CONTENT_DATA_FAILED),
  getHelpContentDataSuccess: createPayloadAction(GET_HELP_CONTENT_DATA_SUCCESS),
  getBannerContentData: createEmptyAction(GET_BANNER_CONTENT_DATA_START),
  getBannerContentDataFailed: createEmptyAction(GET_BANNER_CONTENT_DATA_FAILED),
  getBannerContentDataSuccess: createPayloadAction(GET_BANNER_CONTENT_DATA_SUCCESS),
  getAccountNotificationsData: createEmptyAction(GET_ACCOUNT_NOTIFICATIONS_DATA_START),
  getAccountNotificationsDataFailed: createEmptyAction(GET_ACCOUNT_NOTIFICATIONS_DATA_FAILED),
  getAccountNotificationsDataSuccess: createPayloadAction(GET_ACCOUNT_NOTIFICATIONS_DATA_SUCCESS),
};

export default actions;
