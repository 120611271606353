import { useEffect } from "react";
import Cookies from "universal-cookie";
import { MD5 } from "crypto-js";

const useSetHashedCookies = (name, value, options) => {
  useEffect(() => {
    const cookies = new Cookies(null, options);
    if (value) {
      const hashedValue = MD5(value).toString();
      cookies.set(name, hashedValue);
    }
  }, [value, name, options]);
};

export default useSetHashedCookies;