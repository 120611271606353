import React from "react";
import ReactModal from 'react-modal';
import {useTransition, animated} from 'react-spring'
import cx from "classnames";

const Modal = (props) => {
  const { header, children, footer, onClose, visible, closeable, size } = props;
  
  const modalContentLabel = `${header ?? ""} Modal`;
  
  const attemptClose = () => {
    if (closeable) {
      onClose();
    }
  };

  const transitions = useTransition(visible, null, {
    from: { opacity: 0, transform: 'translate3d(0,-40px,0) scale(0.98)' },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0) scale(1)' },
    leave: { opacity: 0, transform: 'translate3d(0,40px,0) scale(0.98)' },
  });

  return transitions.map(({item, key, props: spring}) => (
      <ReactModal
        isOpen={item}
        key={key}
        className="modal-container"
        overlayClassName={cx("modal-overlay", {"is-closing": !!item !== visible})}
        parentSelector={() => document.querySelector('#modal-parent')}
        contentLabel={modalContentLabel}
        onRequestClose={attemptClose}
        shouldCloseOnOverlayClick={true}
      >
        <animated.div style={spring} className={`${size || 'medium'} modal-content`}>
          <header className="modal-Header">
            <div className="modal-empty-div"/>
            <div className="modal-Title">{header}</div>
            {closeable && <button type="button" aria-label="close" className="modal-Close-Icon" onClick={attemptClose}>×</button>}
          </header>
          <div className="modal-Body">{children}</div>
          <footer className="modal-Footer">{footer}</footer>
        </animated.div>
      </ReactModal>
    )
  );
};

export default Modal;
